// export const OwnerProperty= [
//     {
//         id:0,
//         xSlideImagesArr:[
//             {
//                 url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
        
           
//             {
//                 url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//         ],
//         xArea:"2957",
//         xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
//         xNoOfBath:"2",
//         xNoOfBeds:"3",
//         xPropertyName:"Property 1",
//         xRefNumber:"KE 123456",
//         xTrending: true,
//         xPrice:'200,000'
//     },
//     {
//         id:1,
//         xSlideImagesArr:[
            
             
//             // {
//             //     url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
//             //     caption: 'Slide 1'
//             // },
//             {
//                 url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 2'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//         ],
//         xArea:"2957",
//         xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
//         xNoOfBath:"2",
//         xNoOfBeds:"3",
//         xPropertyName:"Property 1",
//         xRefNumber:"KE 123456",
//         xTrending: false,
//          xPrice:'340,000'
//     },
//     {
//         id:2,
//         xSlideImagesArr:[
          
//             {
//                 url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
        
//             {
//                 url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 1'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 2'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//         ],
//         xArea:"2957",
//         xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
//         xNoOfBath:"2",
//         xNoOfBeds:"3",
//         xPropertyName:"Property 1",
//         xRefNumber:"KE 123456",
//         xTrending: true,
//          xPrice:'120,000'
//     },
//     {
//         id:3,
//         xSlideImagesArr:[
//             {
//                 url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
        
//             {
//                 url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 1'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 2'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
         
//         ],
//         xArea:"2957",
//         xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
//         xNoOfBath:"2",
//         xNoOfBeds:"3",
//         xPropertyName:"Property 1",
//         xRefNumber:"KE 123456",
//         xTrending: false,
//          xPrice:'908,000'
//     },
//     {
//         id:4,
//         xSlideImagesArr:[
//             {
//                 url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
        
//             {
//                 url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 1'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 2'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//         ],
//         xArea:"2957",
//         xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
//         xNoOfBath:"2",
//         xNoOfBeds:"3",
//         xPropertyName:"Property 1",
//         xRefNumber:"KE 123456",
//         xTrending: true,
//          xPrice:'230,000'
//     },
//     {
//         id:5,
//         xSlideImagesArr:[
//             {
//                 url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
        
//             {
//                 url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 1'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 2'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//         ],
//         xArea:"2957",
//         xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
//         xNoOfBath:"2",
//         xNoOfBeds:"3",
//         xPropertyName:"Property 1",
//         xRefNumber:"KE 123456",
//         xTrending: false,
//          xPrice:'450,000'
//     },
//     {
//         id:6,
//         xSlideImagesArr:[
//             {
//                 url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
        
//             {
//                 url:'https://images.pexels.com/photos/5570224/pexels-photo-5570224.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 1'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19075398/pexels-photo-19075398/free-photo-of-swimming-pool-in-yard-of-luxury-residence.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 2'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/14358804/pexels-photo-14358804.jpeg?auto=compress&cs=tinysrgb&w=800',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/5563473/pexels-photo-5563473.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//             {
//                 url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load',
//                 caption: 'Slide 3'
//             },
//         ],
//         xArea:"2957",
//         xDescription:"subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur subtitle1. Lorem ipsum dolor sit amet, consectetur adipisicing elit. Quos blanditiis tenetur",
//         xNoOfBath:"2",
//         xNoOfBeds:"3",
//         xPropertyName:"Property 1",
//         xRefNumber:"KE 123456",
//         xTrending: true,
//          xPrice:'340,000'
//     },

//   ]

export const properties = [
{ 
    id: 1, 
    name: 'Property 1', 
    price: '100,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: true,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 2, 
    name: 'Property 2', 
    price: '150,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: false,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 3, 
    name: 'Property 3', 
    price: '200,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: true,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 4, 
    name: 'Property 4', 
    price: '180,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: false,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 5, 
    name: 'Property 5', 
    price: '220,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: true,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 6, 
    name: 'Property 6', 
    price: '250,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: true,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 7, 
    name: 'Property 1', 
    price: '100,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: false,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147291/pexels-photo-7147291.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 8, 
    name: 'Property 2', 
    price: '150,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: true,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7018402/pexels-photo-7018402.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 9, 
    name: 'Property 3', 
    price: '200,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: false,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7147298/pexels-photo-7147298.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 10, 
    name: 'Property 4', 
    price: '180,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: true,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/7214165/pexels-photo-7214165.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 11, 
    name: 'Property 5', 
    price: '220,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: true,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/6890399/pexels-photo-6890399.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
{ 
    id: 12, 
    name: 'Property 6', 
    price: '250,000', 
    xNoOfBeds:'3',
    xNoOfBath:'2',
    xArea:'1240',
    xRefNumber:'KE 123456',
    trending: false,
    imageUrl:[
        { url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
        { url: 'https://images.pexels.com/photos/19013522/pexels-photo-19013522/free-photo-of-a-house-with-a-swimming-pool-in-the-yard.jpeg?auto=compress&cs=tinysrgb&w=800&lazy=load'},
    ] 
    },
// Add more properties as needed
];

export const OwnerProperty = [
    {
        id:0,
        xSlideImagesArr:[
            {
                url: 'https://kestates.ae/wp-content/uploads/2024/01/SharedScreenshotfff.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://kestates.ae/wp-content/uploads/2024/01/Sky_Residences_Interior_Image_04.png',
                caption: 'Slide 3'
            },
            {
                url: 'https://kestates.ae/wp-content/uploads/2024/01/Sky_Residences_Interior_Image_01.png',
                caption: 'Slide 3'
            },
            {
                url: 'https://kestates.ae/wp-content/uploads/2024/01/Sky-Residences-Expo-.jpg',
                caption: 'Slide 3'
            },
        
        
            {
                url: 'https://kestates.ae/wp-content/uploads/2024/01/main.jpg',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"Sky Residences offers an urban living experience that emphasizes health, wellness, community engagement, and environmental consciousness. Experience a lifestyle that seamlessly blends smart, eco-friendly design with luxurious amenities, all while ensuring convenient access to the city’s highlights. Sky Residences epitomizes the belief that true well-being stems from a harmonious balance of living well and being well. This principle is embodied in its community-focused design, which includes lush green spaces, jogging paths, and modern wellness facilities, all aimed at nurturing a joyful and healthy lifestyle. The commitment to communal living at Sky Residences is showcased through its three levels dedicated to social activities. These include beautifully landscaped podium gardens and inviting sky terraces, offering ideal spaces for quality time with family and friends. Residents can relish the convenience and enjoyment of a lively urban community, right outside their doors.",
        xNoOfBath:"2",
        xNoOfBeds:"3",
        xPropertyName:"Sky Residences, Expo City",
        xRefNumber:"1101",
        xTrending: true,
        xPrice: '4,427,000',
        listed_with_Kestates: true,
    },

    {
        id:1,
        xSlideImagesArr:[
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_2_1d98033a7e.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_1_353493cd4a.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_6_e16d38b15c.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_7_c92876a21d.jpg',
                caption: 'Slide 3'
            },
        
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_5_93d6072730.jpg',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"Sky Residences offers an urban living experience that emphasizes health, wellness, community engagement, and environmental consciousness. Experience a lifestyle that seamlessly blends smart, eco-friendly design with luxurious amenities, all while ensuring convenient access to the city’s highlights. Sky Residences epitomizes the belief that true well-being stems from a harmonious balance of living well and being well. This principle is embodied in its community-focused design, which includes lush green spaces, jogging paths, and modern wellness facilities, all aimed at nurturing a joyful and healthy lifestyle. The commitment to communal living at Sky Residences is showcased through its three levels dedicated to social activities. These include beautifully landscaped podium gardens and inviting sky terraces, offering ideal spaces for quality time with family and friends. Residents can relish the convenience and enjoyment of a lively urban community, right outside their doors.",
        xNoOfBath:"5",
        xNoOfBeds:"4",
        xPropertyName:"Sky Residences 2, Expo City",
        xRefNumber:"1102",
        xTrending: true,
        xPrice: '3,016,000',
        listed_with_Kestates: false,
    },
    {
        id:2,
        xSlideImagesArr:[
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_6_e16d38b15c.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_5_93d6072730.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_1_353493cd4a.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_7_c92876a21d.jpg',
                caption: 'Slide 3'
            },
        
            {
                url: 'https://manage.tanamiproperties.com/Gallery/1541/Thumb/13869.webp',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"Sky Residences offers an urban living experience that emphasizes health, wellness, community engagement, and environmental consciousness. Experience a lifestyle that seamlessly blends smart, eco-friendly design with luxurious amenities, all while ensuring convenient access to the city’s highlights. Sky Residences epitomizes the belief that true well-being stems from a harmonious balance of living well and being well. This principle is embodied in its community-focused design, which includes lush green spaces, jogging paths, and modern wellness facilities, all aimed at nurturing a joyful and healthy lifestyle. The commitment to communal living at Sky Residences is showcased through its three levels dedicated to social activities. These include beautifully landscaped podium gardens and inviting sky terraces, offering ideal spaces for quality time with family and friends. Residents can relish the convenience and enjoyment of a lively urban community, right outside their doors.",
        xNoOfBath:"5",
        xNoOfBeds:"4",
        xPropertyName:"Sky Residences 2, Expo City",
        xRefNumber:"1103",
        xTrending: false,
        xPrice: '3,015,000',
        listed_with_Kestates: true,
    },
    {
        id:3,
        xSlideImagesArr:[
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_5_93d6072730.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_1_353493cd4a.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_7_c92876a21d.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_7_c92876a21d.jpg',
                caption: 'Slide 3'
            },
        
            {
                url: 'https://manage.tanamiproperties.com/Gallery/1541/Thumb/13869.webp',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"Sky Residences offers an urban living experience that emphasizes health, wellness, community engagement, and environmental consciousness. Experience a lifestyle that seamlessly blends smart, eco-friendly design with luxurious amenities, all while ensuring convenient access to the city’s highlights. Sky Residences epitomizes the belief that true well-being stems from a harmonious balance of living well and being well. This principle is embodied in its community-focused design, which includes lush green spaces, jogging paths, and modern wellness facilities, all aimed at nurturing a joyful and healthy lifestyle. The commitment to communal living at Sky Residences is showcased through its three levels dedicated to social activities. These include beautifully landscaped podium gardens and inviting sky terraces, offering ideal spaces for quality time with family and friends. Residents can relish the convenience and enjoyment of a lively urban community, right outside their doors.",
        xNoOfBath:"5",
        xNoOfBeds:"4",
        xPropertyName:"Sky Residences 2, Expo City",
        xRefNumber:"1104",
        xTrending: false,
        xPrice: '3,004,000',
        listed_with_Kestates: false,
    },
    {
        id:4,
        xSlideImagesArr:[
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_2_1d98033a7e.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_1_353493cd4a.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_7_c92876a21d.jpg',
                caption: 'Slide 3'
            },
            {
                url: 'https://ggfx-providentestate.s3.eu-west-2.amazonaws.com/i.prod/sky_residences_7_c92876a21d.jpg',
                caption: 'Slide 3'
            },
        
            {
                url: 'https://manage.tanamiproperties.com/Gallery/1541/Thumb/13869.webp',
                caption: 'Slide 3'
            },
        ],
        xArea:"2957",
        xDescription:"Sky Residences offers an urban living experience that emphasizes health, wellness, community engagement, and environmental consciousness. Experience a lifestyle that seamlessly blends smart, eco-friendly design with luxurious amenities, all while ensuring convenient access to the city’s highlights. Sky Residences epitomizes the belief that true well-being stems from a harmonious balance of living well and being well. This principle is embodied in its community-focused design, which includes lush green spaces, jogging paths, and modern wellness facilities, all aimed at nurturing a joyful and healthy lifestyle. The commitment to communal living at Sky Residences is showcased through its three levels dedicated to social activities. These include beautifully landscaped podium gardens and inviting sky terraces, offering ideal spaces for quality time with family and friends. Residents can relish the convenience and enjoyment of a lively urban community, right outside their doors.",
        xNoOfBath:"5",
        xNoOfBeds:"4",
        xPropertyName:"Sky Residences 2, Expo City",
        xRefNumber:"1104",
        xTrending: false,
        xPrice: '3,004,000',
        listed_with_Kestates: false,
    },
]

export const user={
    name: 'Richard Charles Regan Jr',
    email: ' red7eu@gmail.com',
    address: '123 Main St, City, Country',
    phone: '+971 54 531 5288',
    dob: '01/01/1990',
    status: 'Active', // or 'Inactive'
}

export const List_OF_Properties = [
    
    {
        "id": 192,
        "unit_no": "308",
        "title": "ELA by Omniyat",
        "price": 49143640,
        "dld_fee": 61430,
        "area_size": "5586.47",
        "price_per_area": 8796.9,
        "status": "Rent",
        "location": "Business Bay",
        "property_type": "appartment",
        "developer": "Omniyat",
        "flag": 0,
        "description": null,
        "bedrooms": 3,
        "images": []
    },
    {
        "id": 191,
        "unit_no": "508",
        "title": "ELA by Omniyat",
        "price": 50502480,
        "dld_fee": 63128,
        "area_size": "5586.47",
        "price_per_area": 9040.14,
        "status": "Rent",
        "location": "Business Bay",
        "property_type": "appartment",
        "developer": "Omniyat",
        "flag": 0,
        "description": null,
        "bedrooms": 3,
        "images": []
    },
    {
        "id": 190,
        "unit_no": "1105",
        "title": "Sky Residences 2 ",
        "price": 1962000,
        "dld_fee": 2453,
        "area_size": "934.88",
        "price_per_area": 2098.67,
        "status": "Rent",
        "location": "Expo City",
        "property_type": "appartment",
        "developer": "Expo City Mangrove Residences Development FZCO",
        "flag": 0,
        "description": null,
        "bedrooms": 1,
        "images": []
    },
    {
        "id": 189,
        "unit_no": "1104",
        "title": "Sky Residences 2  (2BR-T2)",
        "price": 3004000,
        "dld_fee": 3755,
        "area_size": "1366.15",
        "price_per_area": 2198.88,
        "status": "Rent",
        "location": "Expo City",
        "property_type": "appartment",
        "developer": "Expo City Mangrove Residences Development FZCO",
        "flag": 0,
        "description": null,
        "bedrooms": 2,
        "images": []
    },
    {
        "id": 188,
        "unit_no": "1103",
        "title": "Sky Residences 2  ",
        "price": 3015000,
        "dld_fee": 3769,
        "area_size": "1371.32",
        "price_per_area": 2198.61,
        "status": "Rent",
        "location": "Expo City",
        "property_type": "appartment",
        "developer": "Expo City Mangrove Residences Development FZCO",
        "flag": 0,
        "description": null,
        "bedrooms": 2,
        "images": []
    },
    {
        "id": 187,
        "unit_no": "1102",
        "title": "Sky Residences 2 ",
        "price": 3016000,
        "dld_fee": 3770,
        "area_size": "1372.07",
        "price_per_area": 2198.14,
        "status": "Rent",
        "location": "Expo City",
        "property_type": "appartment",
        "developer": "Expo City Mangrove Residences Development FZCO",
        "flag": 0,
        "description": null,
        "bedrooms": 2,
        "images": []
    },
    {
        "id": 186,
        "unit_no": "1101",
        "title": "Sky Residences 2 ",
        "price": 4427000,
        "dld_fee": 5534,
        "area_size": "2186.83",
        "price_per_area": 2024.39,
        "status": "Rent",
        "location": "Expo City",
        "property_type": "appartment",
        "developer": "Expo City Mangrove Residences Development FZCO",
        "flag": 0,
        "description": null,
        "bedrooms": 3,
        "images": []
    },
    {
        "id": 185,
        "unit_no": "304",
        "title": "Mangrove Tower 2 ",
        "price": 2459721,
        "dld_fee": 3075,
        "area_size": "1378.00",
        "price_per_area": 1784.99,
        "status": "Rent",
        "location": "Expo City",
        "property_type": "appartment",
        "developer": "Expo City Mangrove Residences Development FZCO",
        "flag": 0,
        "description": null,
        "bedrooms": 2,
        "images": []
    },
    {
        "id": 184,
        "unit_no": "306",
        "title": "Mangrove Tower 2 ",
        "price": 2419261,
        "dld_fee": 3024,
        "area_size": "1420.00",
        "price_per_area": 1703.7,
        "status": "Rent",
        "location": "Expo City",
        "property_type": "appartment",
        "developer": "Expo City Mangrove Residences Development FZCO",
        "flag": 0,
        "description": null,
        "bedrooms": 2,
        "images": []
    },
    {
        "id": 183,
        "unit_no": "503",
        "title": "Mangrove Tower 2 ",
        "price": 2549936,
        "dld_fee": 3187,
        "area_size": "1423.00",
        "price_per_area": 1791.94,
        "status": "Rent",
        "location": "Expo City",
        "property_type": "appartment",
        "developer": "Expo City Mangrove Residences Development FZCO",
        "flag": 0,
        "description": null,
        "bedrooms": 2,
        "images": []
    },
    {
        "id": 182,
        "unit_no": "201",
        "title": "Mangrove Tower 3 ",
        "price": 3534293,
        "dld_fee": 4418,
        "area_size": "1933.00",
        "price_per_area": 1828.4,
        "status": "Rent",
        "location": "Expo City",
        "property_type": "appartment",
        "developer": "Expo City Mangrove Residences Development FZCO",
        "flag": 0,
        "description": null,
        "bedrooms": 3,
        "images": []
    },
    {
        "id": 181,
        "unit_no": "602",
        "title": "Vela ",
        "price": 44241500,
        "dld_fee": 55302,
        "area_size": "6047.00",
        "price_per_area": 7316.27,
        "status": "Rent",
        "location": "Business Bay",
        "property_type": "appartment",
        "developer": "Omniyat",
        "flag": 0,
        "description": null,
        "bedrooms": 3,
        "images": []
    },
    {
        "id": 180,
        "unit_no": "TAG-SM2-008",
        "title": "Serenity Mansions ",
        "price": 33425000,
        "dld_fee": 41781,
        "area_size": "10033.26",
        "price_per_area": 3331.42,
        "status": "Rent",
        "location": "Tilal Alghaf",
        "property_type": "villa",
        "developer": "MAF",
        "flag": 0,
        "description": null,
        "bedrooms": 6,
        "images": []
    },
    {
        "id": 179,
        "unit_no": "TAG-SM2-006",
        "title": " Serenity Mansions ",
        "price": 33187000,
        "dld_fee": 41484,
        "area_size": "10033.15",
        "price_per_area": 3307.73,
        "status": "Rent",
        "location": "Tilal Alghaf",
        "property_type": "villa",
        "developer": "MAF",
        "flag": 0,
        "description": null,
        "bedrooms": 6,
        "images": []
    },
    {
        "id": 178,
        "unit_no": "2603 ",
        "title": "Beachgate by Address ",
        "price": 9574000,
        "dld_fee": 13441,
        "area_size": "2420.00",
        "price_per_area": 3956.2,
        "status": "Rent",
        "location": "Palm Jumeirah",
        "property_type": "appartment",
        "developer": "Emaar",
        "flag": 0,
        "description": null,
        "bedrooms": 4,
        "images": []
    },
    {
        "id": 177,
        "unit_no": "2503 ",
        "title": "Palm Beach Tower 1 ",
        "price": 9574000,
        "dld_fee": 11968,
        "area_size": "2553.00",
        "price_per_area": 3750.1,
        "status": "Rent",
        "location": "Palm Jumeirah",
        "property_type": "appartment",
        "developer": "Nakheel",
        "flag": 0,
        "description": null,
        "bedrooms": 3,
        "images": []
    },
    {
        "id": 176,
        "unit_no": "2504 ",
        "title": "Palm Beach Tower 1",
        "price": 2971000,
        "dld_fee": 3714,
        "area_size": "922.00",
        "price_per_area": 3222.34,
        "status": "Rent",
        "location": "Palm Jumeirah",
        "property_type": "appartment",
        "developer": "Nakheel",
        "flag": 0,
        "description": null,
        "bedrooms": 1,
        "images": []
    },
    {
        "id": 175,
        "unit_no": "2006 ",
        "title": "Palm Beach Tower 2 ",
        "price": 5328800,
        "dld_fee": 6661,
        "area_size": "1775.00",
        "price_per_area": 3002.14,
        "status": "Rent",
        "location": "Palm Jumeirah",
        "property_type": "appartment",
        "developer": "Nakheel",
        "flag": 0,
        "description": null,
        "bedrooms": 2,
        "images": []
    },
    {
        "id": 174,
        "unit_no": "303 ",
        "title": "Palm Beach Tower 1 ",
        "price": 4668800,
        "dld_fee": 5836,
        "area_size": "1641.00",
        "price_per_area": 2845.09,
        "status": "Rent",
        "location": "Palm Jumeirah",
        "property_type": "appartment",
        "developer": "Nakheel",
        "flag": 0,
        "description": null,
        "bedrooms": 2,
        "images": []
    }
]

// export const List_Of_Payment_Plan = {
//         "success": true,
//         "property": {
//             "id": 174,
//             "title": "Palm Beach Tower 1 ",
//             "price": 4668800,
//             "area_size": "1641.00",
//             "status": "Rent",
//             "location": "Palm Jumeirah",
//             "property_type": "appartment",
//             "developer": "Nakheel",
//             "flag": 0,
//             "description": null,
//             "bedrooms": 2,
//             "images": []
//         },
//         "payment_plan": {
//             "id": 1,
//             "property_id": 174,
//             "amount": 46688000,
//             "due_date": "2024-06-15",
//             "installment": "Fifth Installment 10%",
//             "created_at": null,
//             "updated_at": null,
//             "property_payment_details": [
//                 {
//                     "id": 1,
//                     "payment_id": 1,
//                     "amount": 18784700,
//                     "expect_date": null,
//                     "installment_details": "4% DLD & Admin Fee"
//                 },
//                 {
//                     "id": 2,
//                     "payment_id": 1,
//                     "amount": 70032000,
//                     "expect_date": null,
//                     "installment_details": "Booking Deposit - 15%"
//                 },
//                 {
//                     "id": 3,
//                     "payment_id": 1,
//                     "amount": 46688000,
//                     "expect_date": "2022-12-15",
//                     "installment_details": "Second Installment 10% (15-Dec-2022)"
//                 },
//                 {
//                     "id": 4,
//                     "payment_id": 1,
//                     "amount": 46688000,
//                     "expect_date": "2023-06-15",
//                     "installment_details": "Third Installment 10% (15-Jun-2023)"
//                 },
//                 {
//                     "id": 5,
//                     "payment_id": 1,
//                     "amount": 46688000,
//                     "expect_date": "2023-12-15",
//                     "installment_details": "Fourth Installment 10% (15-Dec-2023)"
//                 },
//                 {
//                     "id": 6,
//                     "payment_id": 1,
//                     "amount": 46688000,
//                     "expect_date": "2024-06-15",
//                     "installment_details": "Fifth Installment 10% (15-Jun-2024)"
//                 },
//                 {
//                     "id": 7,
//                     "payment_id": 1,
//                     "amount": 23344000,
//                     "expect_date": "2024-12-15",
//                     "installment_details": "Sixth Installment 5% (15-Dec-2024)"
//                 },
//                 {
//                     "id": 8,
//                     "payment_id": 1,
//                     "amount": 186752000,
//                     "expect_date": null,
//                     "installment_details": "Final Installment 40% (On Handover)"
//                 }
//             ]
//         }
//     }

export const List_Of_Payment_Plan1 = [
    {
        id:1,
        number_of_installment:'deposit',
        installment:"Expresion of interest effective Date - Booking Fee",
        Paymnet_perccentage:"5%",
        amount: 2457182.00
    },
    {
        id:2,
        number_of_installment:'1st Installment',
        installment:"On Sale & Perchase Agreemnet Effective Date",
        Paymnet_perccentage:"20%",
        amount: 9828728.00
    },
    {
        id:3,
        number_of_installment:'2nd Installment',
        installment:"On Substantial completion of Residential Tower Enabling works",
        Paymnet_perccentage:"10%",
        amount: 4914264.00
    },
    {
        id:4,
        number_of_installment:'3rd Installment',
        installment:"On Substantial completion of Residential Tower Ground Floor",
        Paymnet_perccentage:"5%",
        amount: 2457182.00
    },
    {
        id:5,
        number_of_installment:'4th Installment',
        installment:"On Substantial completion of Residential Tower Super Structure",
        Paymnet_perccentage:"5%",
        amount: 2457182.00
    },
    {
        id:6,
        number_of_installment:'1st Installment',
        installment:"On Substantial completion of Residential Tower Window Glazing Installation",
        Paymnet_perccentage:"5%",
        amount: 2457182.00
    },
    {
        id:7,
        number_of_installment:'1st Installment',
        installment:"On Project Completion Date",
        Paymnet_perccentage:"50%",
        amount: 24571820.00
    },
]
