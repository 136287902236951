import React, { useEffect, useState } from 'react';
import { 
    Button,
    Input,
    Typography,
    Avatar,
    Grid,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl,
    CircularProgress,
    Snackbar
} from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import { Paper } from '@mui/material';
// Local
import profileImage from "../../Images/img.jpeg";
import SuccessLoader from "../../Images/success.json";
import "./index.css";
// import { user } from '../../LocalDB';
import { useDispatch, useSelector } from 'react-redux';
import { httpRequest } from '../Server/Axios';
import { GET_LIST_OF_COUNTRIES, GET_USER_DETAIL_API, POST_UPDATE_USER_DETAIL_API } from '../Server/Apis';
import LottieAnimationLoader from '../../Components/lottieAnimation';
import Lottie from 'lottie-react';
import { loginSuccess } from '../Login/sliceLogin';
import { profileImageBaseUrl } from '../Server/baseUrls';

const Setting = () => {
    const userInfo = useSelector((state) => state.login.user);
    const tokenInfo = useSelector((state) => state.login.token);
    const dispatch = useDispatch();

    console.log("**** userInfo ****** ", userInfo)

    const profileImages = `${profileImageBaseUrl}/${userInfo.profile_image}`;

    const [image, setImage] = useState(profileImages? profileImages : null);
    const [name, setName] = useState(userInfo.name ? userInfo.name : "");
    const [email, setEmail] = useState(userInfo.email ? userInfo.email : "");
    const [number, setNumber] = useState(userInfo.phone ? userInfo.phone : "");
    const [dob, setDob] = useState(userInfo.dob ? userInfo.dob : "");
    const [status, setStatus] = useState('active');
    const [address, setAddress] = useState(userInfo.address ? userInfo.address : "");
    const [country, setCountry] = useState("");
    const [Countrylist, setCountryList] = useState([]);
    const [loader, setLoader] = useState(false);
    const [sError, setError] = useState('');
    const [open, setOpen] = React.useState(false);
    const [open1, setOpen1] = React.useState(false);
    const [userDetail, setUserDetail] = useState(null);

    useEffect(() => {
        getListOfCountries();
        // getUserDetails();
    }, []);

    const getUserDetails = async() => {
        const API_GET_USER_DETAILS_CONFIG = GET_USER_DETAIL_API(tokenInfo);
        
        try {
            const response = await httpRequest(API_GET_USER_DETAILS_CONFIG);
            // console.log("**** Response **** ", response)
            setUserDetail(response)
        } catch (error) {
        }
    }
  
    const handleNameChange = (event) => {
      setName(event.target.value);
    };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };

    const handleNumberChange = (event) => {
        setNumber(event.target.value);
      };
  
    const handleDobChange = (event) => {
      setDob(event.target.value);
      
    };
  
    const handleStatusChange = (event) => {
      setStatus(event.target.value);
    };
  
    const handleAddressChange = (event) => {
      setAddress(event.target.value);
    };
  
    const handleCountryChange = (event) => {
      setCountry(event.target.value);
    };
  
    const getUpdateUserDetail = async() => {
        setLoader(true)
        const API_POST_UPDATE_USER_DETAILS_CONFIG = POST_UPDATE_USER_DETAIL_API(
            tokenInfo,
            name,
            email,
            number,
            address,
            country,
            dob,
            image,
        );

        try {
            const response = await httpRequest(API_POST_UPDATE_USER_DETAILS_CONFIG);
            // console.log("******* response ******* ", response);
            if(response.success){
                setLoader(false);
                setError(response.msg)
                setOpen1(true);
                dispatch( loginSuccess(response) );
            }
            else{
                setLoader(false);
                setError(response.data.email)
                setOpen(true)
            }
        } catch (error) {
            setLoader(false)
            setError("Something went wrong!")
            setOpen(true)
        }
    }

    const getListOfCountries = async() => {
        const API_GET_LIST_OF_COUNTRIES = GET_LIST_OF_COUNTRIES( tokenInfo );
        
        try {
            const response = await httpRequest(API_GET_LIST_OF_COUNTRIES);
            // console.log("**** Countries List  ****** ", response)
            
            if(response.success){
                setCountryList(response.countries);
                // const checkCountry = response.countries.filter((data) => {
                // console.log("**** data Country here ****** ", data)

                //     if(data.name == userInfo.nationality){
                //         return data;
                //     }   
                // } );
                // console.log("**** Check Country here ****** ", checkCountry)

            }
        } 
        catch (error) {
        }
    }

    // Function to handle file input change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
        setImage(reader.result);
        };

        if (file) {
        reader.readAsDataURL(file);
        }
    };

    const handleClose = () => {
        setOpen(false);
      };
      const handleClose1 = () => {
        setOpen1(false);
      };

    return(
        <div style={{ width:"100%", display:'flex', alignItems:"center" , justifyContent:"center"}} >
            <div className='setting-container' >
                {/* <h1> User Acount </h1> */}
                <Typography variant="h5" style={{ fontFamily:'Montserrat'}} >{userInfo.name}</Typography>
                <Typography variant="caption" style={{ fontFamily:'Montserrat'}} >{userInfo.address}</Typography>

                <Grid container spacing={2} alignItems={'center'} flexDirection={'column'} >
                    <Grid item className='change-profile' >
                        <div style={{ alignItems:"center" }} >
                            <Avatar alt="Profile Picture" src={image} style={{ width:'9rem', height:'9rem' }} />
                            <Grid item >
                                <Input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="upload-button"
                                />
                                <label htmlFor="upload-button">
                                    <Button variant="outlined" color="info" component="span" size='small' endIcon={<EditIcon />} style={{ marginTop:'1rem', marginLeft:'1rem' }}>
                                        change
                                    </Button>
                                </label>
                            </Grid>
                        </div>
                        {/* <div style={{ marginLeft: '1rem' }}>
                            <Typography variant="body1" style={{ fontFamily:'Montserrat'}} >{user.name}</Typography>
                            <Typography variant="body1" style={{ fontFamily:'Montserrat'}} >{user.address}</Typography>
                        </div> */}
                    </Grid>
                    
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Change Name"
                            value={name}
                            onChange={handleNameChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Change Email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Change Number"
                            value={number}
                            onChange={handleNumberChange}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            type="date"
                            // label="Change DOB"
                            value={dob}
                            onChange={handleDobChange}
                        />
                    </Grid>

                    {/* <Grid item xs={12} md={12} lg={6} xl={6}>
                        <RadioGroup value={status} onChange={handleStatusChange} style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:"flex-start" }} >
                            <FormControlLabel value="active" control={<Radio />} label="Active" />
                            <FormControlLabel value="inactive" control={<Radio />} label="Inactive" />
                        </RadioGroup>
                    </Grid> */}
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Change Address"
                            value={address}
                            onChange={handleAddressChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                fullWidth
                                label="Select Country"
                                value={country}
                                onChange={handleCountryChange}
                            >
                               {
                                    Countrylist.map((item, index) => {
                                        return(
                                            <MenuItem key={index} value={item.id}>{item.name}</MenuItem>
                                        )
                                    })
                               }
                            </Select>
                        </FormControl>
                    </Grid>

                </Grid>
     

                <Grid item xs={12} style={{ marginTop: '4rem', display:'flex', alignItems:"center", justifyContent:"center" }}>
                    {
                        loader ? 
                           <CircularProgress style={{ color:"black", marginTop:"1rem", marginBottom:"1rem" }}  />
                            :
                            (
                                open1 ? 
                                <Lottie animationData={SuccessLoader} loop={false} style={{ width:'3.25rem', height:"3.25rem" }} onComplete={()=>{
                                    handleClose1();
                                }} 
                                />
                                :
                                <Button
                                    variant="contained"
                                    // color="primary"
                                    style={{ backgroundColor:'black', marginTop:"1rem", marginBottom:"1rem" }}
                                    onClick={getUpdateUserDetail}
                                >
                                    Update Profile
                                </Button>
                            )
                    }
                </Grid>
            </div>
            <Snackbar
                open={open}
                autoHideDuration={5000}
                onClose={handleClose}
                message={sError}
                style={{ backgroundColor:"red", color:"red" }}
                anchorOrigin={{ vertical:'bottom', horizontal:'right' }}
            />
        </div>
    )
}

export default Setting;