import * as React from "react";
import Container from "@mui/material/Container";
import Typography from "@mui/material/Typography";
import Link from "@mui/material/Link";
import Grid from "@mui/material/Grid";
import { Facebook, Instagram, X, LinkedIn, YouTube, Telegram } from "@mui/icons-material";
import { Box } from "@mui/material";

// Local 

import "./index.css";

export default function Footer() {
  return (
    // <Box bgcolor="#111" py={6}>
    //   <Container maxWidth="lg">
    //     <Grid container spacing={4} alignItems="center" justifyContent={'space-between'}>
    //       <Grid item xs={12} sm={4}>
    //         <Typography variant="h5" fontWeight="bold" color="white" gutterBottom>
    //           Contact Us
    //         </Typography>
    //         <Typography variant="body2" color="white">
    //           402, Park Heights Square 1, Dubai Hills, 12205 Dubai
    //         </Typography>
    //         <Typography variant="body2" color="white">
    //           Email: info@kestates.ae
    //         </Typography>
    //         <Typography variant="body2" color="white">
    //           Phone: (04) 529 9724
    //         </Typography>
    //       </Grid>
    //       <Grid item xs={12} sm={4}>
    //         <Typography variant="h5" fontWeight="bold" color="white" gutterBottom>
    //           Follow Us
    //         </Typography>
    //         <Link href="https://www.facebook.com/" color="inherit">
    //           <Facebook fontSize="large" style={{ color:'white' }} sx={{ mr: 1 }} />
    //         </Link>
    //         <Link href="https://www.instagram.com/" color="inherit">
    //           <Instagram fontSize="large" style={{ color:'white' }} sx={{ mr: 1 }} />
    //         </Link>
    //         <Link href="https://www.twitter.com/" color="inherit">
    //           <X fontSize="large" style={{ color:'white' }} />
    //         </Link>
    //       </Grid>
    //     </Grid>
    //     <Box mt={4} textAlign="center">
    //       <Typography variant="body2" color="white">
    //         &copy; {new Date().getFullYear()} K Estates. All rights reserved.
    //       </Typography>
    //     </Box>
    //   </Container>
    // </Box>

    <Box bgcolor="#111" py={6}>
      <Container maxWidth="l">
        <Grid container spacing={4} alignItems="center" justifyContent={'space-between'} >
          <Grid item xs={12} sm={6} md={6} >
            <Typography variant="subtitle2" color="#ffee" marginBottom={'1rem'} fontSize={'0.75rem'} >  
              K Estates &copy; {new Date().getFullYear()} All rights reserved.
            </Typography>
            <Link href="https://www.facebook.com/" color="inherit" style={{ border: 'solid 1px #ffee', borderRadius:100, padding:'6px', marginRight:"0.5rem"}} >
              <Facebook fontSize="small" style={{ color:'white', marginBottom:"2px" }}  />
            </Link>
            <Link href="https://www.instagram.com/" color="inherit" style={{ border: 'solid 1px #ffee', borderRadius:100, padding:'6px', marginRight:"0.5rem"}} >
              <Instagram fontSize="small" style={{ color:'white', marginBottom:"2px" }} />
            </Link>
            <Link href="https://www.twitter.com/" color="inherit" style={{ border: 'solid 1px #ffee', borderRadius:100, padding:'6px', marginRight:"0.5rem"}} >
              <LinkedIn fontSize="small" style={{ color:'white', marginBottom:"2px" }} />
            </Link>
            <Link href="https://www.twitter.com/" color="inherit" style={{ border: 'solid 1px #ffee', borderRadius:100, padding:'6px', marginRight:"0.5rem"}} >
              <YouTube fontSize="small" style={{ color:'white', marginBottom:"2px" }} />
            </Link>
            <Link href="https://www.twitter.com/" color="inherit" style={{ border: 'solid 1px #ffee', borderRadius:100, padding:'6px', marginRight:"0.5rem"}} >
              <Telegram fontSize="small" style={{ color:'white', marginBottom:"2px" }} />
            </Link>
          </Grid>

          <Grid item xs={12} sm={6} md={6}  className="footer_section">
            <div>
              <Typography variant="body1" color="#ffee" gutterBottom>
                Contact Us
              </Typography>
              <Typography variant="subtitle2" fontSize={'0.75rem'} color="#ffee">
                402, Park Heights Square 1, Dubai Hills, 12205 Dubai
              </Typography>
              <Typography variant="subtitle2" fontSize={'0.75rem'} color="#ffee">
                Email: info@kestates.ae
              </Typography>
              <Typography variant="subtitle2" fontSize={'0.75rem'} color="#ffee">
                Phone: (04) 529 9724
              </Typography>
            </div>
            </Grid>
        </Grid>
      </Container>
    </Box>
  );
}