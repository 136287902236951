import React, { useEffect, useState } from 'react'
import { 
    Container,
    Typography,
    Button,
    Grid,
    Alert,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Snackbar
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Table, Drawer, Divider, Skeleton, Modal, Input  } from "antd";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import { BaseImageUrl } from '../../../Components/BaseImageUrl';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { BaseUrl } from '../../../Components/BaseUrl';
import { baseUrl } from '../../Server/baseUrls';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

function Locations() {
    const [locations, setLocations] = useState([]);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [id, setID] = useState("");
    const [showAdd, setShowAdd] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [errors, setErrors] = useState({});
    const [open, setOpen] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [client, setClient] = useState({});
    const [profileLoading, setProfileLoading] = useState(false);
    const [recordID, setRecordID] = useState("");
    const [isLoading,setIsLoading] = useState(false)
    const token = useSelector((state) => state.login.token);
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`,
        },
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
            return;
        }
        setShowToast(false);
    };

    const handleShowAdd = () => {
        setName("");
        setShowAdd(true);
    };

    const handleCloseAdd = () => {
        setShowAdd(false);
        setErrors({});
    };

    const handleAddOk = () => {
        if (validateForm()) {
            setIsLoading(true)
            const data = { name: name };
            axios.post(baseUrl + "/api/locations", data, axiosConfig)
                .then((res) => {
                    if (res.data.success) {
                        setIsLoading(false)
                        setName("");
                        setShowAdd(false);
                        const page = 1;
                        const perPage = 10;
                        fetchData(page, perPage);
                    }
                })
                .catch((err) =>{
                    setIsLoading(false)
                    console.log("err",err);
                })
        }
    };

    const handleShowUpdate = (record) => {
        setName(record.label);
        setID(record.value);
        setShowUpdate(true);
    };

    const handleCloseUpdate = () => {
        setShowUpdate(false);
        setErrors({});
    };

    const handleUpdateOk = () => {
        if (validateForm()) {
            setIsLoading(true)
            const data = { id: id, name: name };
            axios.post(baseUrl + "/api/updatelocation", data, axiosConfig)
                .then((res) => {
                    if (res.data.success) {
                        setIsLoading(false)
                        setName("");
                        setShowUpdate(false);
                        const page = 1;
                        const perPage = 10;
                        fetchData(page, perPage);
                    }
                }).catch((err) =>{
                    setIsLoading(false)
                    console.log("err",err);
                });
        }
    };

    const validateForm = () => {
        let formIsValid = true;
        let errors = {};

        if (!name) {
            formIsValid = false;
            errors["name"] = "Please enter a name.";
        }

        setErrors(errors);
        return formIsValid;
    };

    useEffect(() => {
        const page = 1;
        const perPage = 10;
        fetchData(page, perPage);
    }, []);

    const fetchData = async (page, perPage) => {
        try {
            setLoading(true);
            const url = `${baseUrl}/api/locations?page=${page}&perPage=${perPage}`;
            const response = await axios.get(url, axiosConfig);
            setLoading(false);
            setLocations(response.data.locations);
        } catch (error) {
            setLoading(false);
            console.error("Error:", error);
        }
    };

    const handleTableChange = (pagination) => {
        const page = pagination.current;
        const perPage = pagination.pageSize;
        fetchData(page, perPage);
    };

   

    const columns = [
        {
            title: "Location",
            dataIndex: "label",
        },
        {
            title: "Actions",
            render: (text, record) => (
                <div className="ms-auto">
                    <Button
                        variant="outlined"
                        color="primary"
                        sx={{
                            marginBottom: '1rem',
                            color: 'black',
                            borderColor: 'black',
                            '&:hover': {
                              borderColor: 'black',
                              backgroundColor: '#f5f5f5',
                            },
                          }}
                        style={{ marginBottom: '1rem' }}
                        startIcon={<ModeEditOutlineIcon />}
                        onClick={() => handleShowUpdate(record)}
                    >
                        Update
                    </Button>
                    <Button className='ml-2'
                            variant="outlined" color="error"
                            // startIcon={<AddIcon />}
                            // onClick={handleOpenModal}
                            style={{ marginBottom: '1rem', marginLeft: '1rem' }}
                            onClick={() => handleDelete(record.value)}
                            startIcon={<DeleteOutlineIcon />}
                        >
                            Delete
                        </Button>
                </div>
            ),
        },
    ];

    const handleDelete = (record_id) => {
        setOpen(true);
        setRecordID(record_id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAgreeDelete = () => {
        axios.delete(baseUrl + "/api/locations/" + recordID, axiosConfig)
            .then((res) => {
                setOpen(false);
                if (res.data.success) {
                    const page = 1;
                    const perPage = 10;
                    setTimeout(() => {
                        fetchData(page, perPage);
                        setShowToast(true);
                    }, 200);
                }
            });
    };

    return (
        <Container maxWidth={'xl'}>
       
            <Snackbar
                open={showToast}
                onClose={handleCloseToast}
                autoHideDuration={1500}
                anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
            >
                <Alert severity="success" variant="filled">
                    Location Successfully deleted
                </Alert>
            </Snackbar>
            <BootstrapDialog onClose={handleClose} aria-labelledby="customized-dialog-title" open={open}>
                <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
                    Delete Location
                </DialogTitle>
                <IconButton
                    aria-label="close"
                    onClick={handleClose}
                    sx={{
                        position: 'absolute',
                        right: 8,
                        top: 8,
                        color: (theme) => theme.palette.grey[500],
                    }}
                >
                    <CloseIcon />
                </IconButton>
                <DialogContent dividers>
                    <Typography gutterBottom>
                        Are you sure to delete? You will not be able to revert this action.
                    </Typography>
                </DialogContent>
                <DialogActions>
                    <Button variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>Close</Button>
                    <Button variant="outlined" onClick={handleAgreeDelete} startIcon={<CheckIcon />}>Continue</Button>
                </DialogActions>
            </BootstrapDialog>
            <Modal width={400} title="Add New Location" open={showAdd} onOk={handleAddOk} onCancel={handleCloseAdd} confirmLoading={isLoading}>
                <Divider />
                <label>Name</label>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
                {errors.name && <Alert severity="error" style={{ marginTop: '1rem' }}>{errors.name}</Alert>}
            </Modal>
            <Modal width={400} title="Update Location" open={showUpdate} onOk={handleUpdateOk} onCancel={handleCloseUpdate} confirmLoading={isLoading}>
                <Divider />
                <label>Name</label>
                <Input value={name} onChange={(e) => setName(e.target.value)} />
                {errors.name && <Alert severity="error" style={{ marginTop: '1rem' }}>{errors.name}</Alert>}
            </Modal>
            <div className=''>
                <Grid container spacing={2} display={'flex'} alignItems={'center'} marginBottom={'1rem'}>
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                        <Typography variant='h3' align='left' width={'100%'} marginBottom={'1rem'}>
                            Locations
                        </Typography>
                    </Grid>
                    <Grid item xs={12} sm={12} md={6} xl={6} lg={6} display={'flex'} alignItems={'center'} justifyContent={'flex-end'}>
                        <Button
                            variant="contained"
                            color="primary"
                            startIcon={<AddIcon />}
                            onClick={handleShowAdd}
                            style={{ marginBottom: '1rem', backgroundColor: "black"  }}
                        >
                            Add New Location
                        </Button>
                    </Grid>
                </Grid>
                <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
                    <Table
                        onChange={handleTableChange}
                        loading={loading}
                        className="table-striped"
                        pagination={{
                            pageSizeOptions: ["10", "20", "50", "100"],
                            total: locations?.total > 0 && locations.total,
                            pageSize: locations.per_page,
                            current: locations.current_page,
                            showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
                            showSizeChanger: true,
                            onShowSizeChange: onShowSizeChange,
                            itemRender: itemRender,
                        }}
                        style={{ overflowX: "auto" }}
                        columns={columns}
                        dataSource={locations?.data ? locations?.data : []}
                    />
                </Grid>
            </div>
        </Container>
    );
}

export default Locations;
