import React, { useRef, useState } from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import SignatureCanvas from 'react-signature-canvas';
import { Snackbar } from '@material-ui/core';
import { baseUrl } from '../../Server/baseUrls';
import {
    Container,
    Typography,
    Button,
    TextField,
    // Select,
    MenuItem,
    Grid,
    Box,
    // Modal,
    Alert
  } from '@mui/material';
import axios from 'axios';
function AgentToAgentAgreementForm() {
  const [formData, setFormData] = useState({
    establishmentName_agent_a: 'K Estates LLC',
    establishmentName_agent_b: '',
    address_agent_a: 'Office 402 Park Heights Square 1, Dubai Hills Estate',
    address_agent_b: '',
    phone_agent_a: '04 529 9724',
    phone_agent_b: '',
    fax_agent_a: '',
    fax_agent_b: '',
    email_agent_a: 'info@kestates.ae',
    email_agent_b: '',
    orn_agent_a: '30498',
    orn_agent_b: '',
    dedLisc_agent_a: '1067808',
    dedLisc_agent_b: '',
    poBox_agent_a: '12205',
    poBox_agent_b: '',
    name_agent_a: '',
    name_agent_b: '',
    brn_agent_a: '',
    brn_agent_b: '',
    dateIssued_agent_a: '',
    dateIssued_agent_b: '',
    mobile_agent_a: '',
    mobile_agent_b: '',
    email_agent_a_form: '',
    email_agent_b_form: '',
    sellerFormA_STR_agent_a: '',
    sellerFormA_STR_agent_b: '',
    propertyAddress: '',
    masterDeveloper: '',
    masterProjectName: '',
    propertyDetails: '',
    listingAgentToComplete: '',
    buildingName: '',
    listedPrice: '',
    description: '',
    isPropertyTenanted: '',
    maintenanceFeePA: '',
    sqFt: '',
    commission_agent_a: '',
    commission_agent_b: '',
    clientName: '',
    familyName: '',
    transferFee_agent_a: '',
    transferFee_agent_b: '',
    budget: '',
    hasPreFinanceApproval: '',
    hasContactedListingAgent: '',
    signature_a:'',
    signature_b:''
  });
  const signaturePadRefA = useRef(null);

  const signaturePadRefB = useRef(null);
  const [showToast, setShowToast] = React.useState(false);

  const handleSignatureEndA = () => {
    if (signaturePadRefA.current) {
        setFormData(prevData => ({
            ...prevData,
            signature_a: signaturePadRefA.current.toDataURL()
        }));
    }
};

  const handleClearSignatureA = () => {
    if (signaturePadRefA.current) {
        signaturePadRefA.current.clear();
        setFormData(prevData => ({
            ...prevData,
            signature_a: ''
        }));
    }
};

const handleSignatureEndB = () => {
    if (signaturePadRefA.current) {
        setFormData(prevData => ({
            ...prevData,
            signature_b: signaturePadRefB.current.toDataURL()
        }));
    }
};

  const handleClearSignatureB = () => {
    if (signaturePadRefB.current) {
        signaturePadRefB.current.clear();
        setFormData(prevData => ({
            ...prevData,
            signature_b: ''
        }));
    }
};
const handleCloseToast = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }

    setShowToast(false);
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
        const response = await axios.post(baseUrl+'/api/agent_to_agent', formData);

        if(response.data.success){

            setShowToast(true)
        //alert('Form submitted successfully');
        setFormData({   establishmentName_agent_a: 'K Estates LLC',
            establishmentName_agent_b: '',
            address_agent_a: 'Office 402 Park Heights Square 1, Dubai Hills Estate',
            address_agent_b: '',
            phone_agent_a: '04 529 9724',
            phone_agent_b: '',
            fax_agent_a: '',
            fax_agent_b: '',
            email_agent_a: 'info@kestates.ae',
            email_agent_b: '',
            orn_agent_a: '30498',
            orn_agent_b: '',
            dedLisc_agent_a: '1067808',
            dedLisc_agent_b: '',
            poBox_agent_a: '12205',
            poBox_agent_b: '',
            name_agent_a: '',
            name_agent_b: '',
            brn_agent_a: '',
            brn_agent_b: '',
            dateIssued_agent_a: '',
            dateIssued_agent_b: '',
            mobile_agent_a: '',
            mobile_agent_b: '',
            email_agent_a_form: '',
            email_agent_b_form: '',
            sellerFormA_STR_agent_a: '',
            sellerFormA_STR_agent_b: '',
            propertyAddress: '',
            masterDeveloper: '',
            masterProjectName: '',
            propertyDetails: '',
            listingAgentToComplete: '',
            buildingName: '',
            listedPrice: '',
            description: '',
            isPropertyTenanted: '',
            maintenanceFeePA: '',
            sqFt: '',
            commission_agent_a: '',
            commission_agent_b: '',
            clientName: '',
            familyName: '',
            transferFee_agent_a: '',
            transferFee_agent_b: '',
            budget: '',
            hasPreFinanceApproval: '',
            hasContactedListingAgent: '',
            signature_a:'',
            signature_b:''})
        // console.log(response.data);
        }
    } catch (error) {
        setShowToast(false)
        console.error('Error submitting form', error);
        alert('Error submitting form');
    }
};

  return (
    <div>
         <Snackbar
        open={showToast}
        onClose={handleCloseToast}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
        Form submitted successfully
        </Alert>
      </Snackbar>
    
    <form className="containerf" onSubmit={handleSubmit}>
      <div className="row">
        <div className="col-md-6">
          
          <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  A) The Agent / Broker (Seller's / Landlord's Agent)
  </div>
  <div class="card-body">
          <div className="mb-3">
            <label>Name of Establishment</label>
            <input
              type="text"
              className="form-control"
              name="establishmentName_agent_a"
              value={formData.establishmentName_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Address</label>
            <input
              type="text"
              className="form-control"
              name="address_agent_a"
              value={formData.address_agent_a}
              onChange={handleChange}
            />
          </div>
          </div>
          </div>
          <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  OFFICE CONTACT DETAILS
  </div>
  <div class="card-body">
          <div className="mb-3">
            <label>PH</label>
            <input
              type="text"
              className="form-control"
              name="phone_agent_a"
              value={formData.phone_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>FAX</label>
            <input
              type="text"
              className="form-control"
              name="fax_agent_a"
              value={formData.fax_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              name="email_agent_a"
              value={formData.email_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>ORN</label>
            <input
              type="text"
              className="form-control"
              name="orn_agent_a"
              value={formData.orn_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>DED LISC</label>
            <input
              type="text"
              className="form-control"
              name="dedLisc_agent_a"
              value={formData.dedLisc_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>P.O. Box</label>
            <input
              type="text"
              className="form-control"
              name="poBox_agent_a"
              value={formData.poBox_agent_a}
              onChange={handleChange}
            />
          </div>
          </div>
          </div>
          <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  NAME OF THE REGISTERED AGENT
  </div>
  <div class="card-body">
          <div className="mb-3">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              name="name_agent_a"
              value={formData.name_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>BRN</label>
            <input
              type="text"
              className="form-control"
              name="brn_agent_a"
              value={formData.brn_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Date Issued</label>
            <input
              type="date"
              className="form-control"
              name="dateIssued_agent_a"
              value={formData.dateIssued_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Mobile</label>
            <input
              type="text"
              className="form-control"
              name="mobile_agent_a"
              value={formData.mobile_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              name="email_agent_a_form"
              value={formData.email_agent_a_form}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Seller's Agent Form A STR#</label>
            <input
              type="text"
              className="form-control"
              name="sellerFormA_STR_agent_a"
              value={formData.sellerFormA_STR_agent_a}
              onChange={handleChange}
            />
          </div>
          </div>
          </div>

          <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  DECLARATION BY AGENT "A"
    </div>
  <div class="card-body">
    <p>
    I hereby declare, I have read and understood the Real Estate Brokers
Code of Ethics, I have a current signed Seller's Agreement FORM A, I
shall respond to a reasonable offer to purchase the listed property
from Agent B, and shall not contact Agent B's Buyer nor confer with
their client under no circumstances unless the nominated Buyer
herein has already discussed the stated listed property with our Office
    </p>
    </div>
    </div>
    <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  PART 2. THE PROPERTY
  </div>
  <div class="card-body">
  <div className="mb-3">
            <label>Property Address</label>
            <input
              type="text"
              className="form-control"
              name="propertyAddress"
              value={formData.propertyAddress}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Master Developer</label>
            <input
              type="text"
              className="form-control"
              name="masterDeveloper"
              value={formData.masterDeveloper}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Master Project Name</label>
            <input
              type="text"
              className="form-control"
              name="masterProjectName"
              value={formData.masterProjectName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Property Details</label>
            <input
              type="text"
              className="form-control"
              name="propertyDetails"
              value={formData.propertyDetails}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Listing Agent to Complete</label>
            <input
              type="text"
              className="form-control"
              name="listingAgentToComplete"
              value={formData.listingAgentToComplete}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Building Name</label>
            <input
              type="text"
              className="form-control"
              name="buildingName"
              value={formData.buildingName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Listed Price</label>
            <input
              type="text"
              className="form-control"
              name="listedPrice"
              value={formData.listedPrice}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Description</label>
            <textarea
              className="form-control"
              name="description"
              value={formData.description}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Is the Property Tenanted?</label>
            <select
              className="form-control"
              name="isPropertyTenanted"
              value={formData.isPropertyTenanted}
              onChange={handleChange}
            >
              <option value="">Select...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="mb-3">
            <label>Maintenance Fee P.A.</label>
            <input
              type="text"
              className="form-control"
              name="maintenanceFeePA"
              value={formData.maintenanceFeePA}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>sq ft</label>
            <input
              type="text"
              className="form-control"
              name="sqFt"
              value={formData.sqFt}
              onChange={handleChange}
            />
          </div>
    </div>
    </div>
 

          {/* end of col1 */}
        </div>

        <div className="col-md-6">
        <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  B) The Agent / Broker (Seller's / Landlord's Agent)
  </div>
  <div class="card-body">
          <div className="mb-3">
            <label>Name of Establishment</label>
            <input
              type="text"
              className="form-control"
              name="establishmentName_agent_b"
              value={formData.establishmentName_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Address</label>
            <input
              type="text"
              className="form-control"
              name="address_agent_b"
              value={formData.address_agent_b}
              onChange={handleChange}
            />
          </div>
          </div>
          </div>
          <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  OFFICE CONTACT DETAILS
  </div>
  <div class="card-body">
          <div className="mb-3">
            <label>PH</label>
            <input
              type="text"
              className="form-control"
              name="phone_agent_b"
              value={formData.phone_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>FAX</label>
            <input
              type="text"
              className="form-control"
              name="fax_agent_b"
              value={formData.fax_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              name="email_agent_b"
              value={formData.email_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>ORN</label>
            <input
              type="text"
              className="form-control"
              name="orn_agent_b"
              value={formData.orn_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>DED LISC</label>
            <input
              type="text"
              className="form-control"
              name="dedLisc_agent_b"
              value={formData.dedLisc_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>P.O. Box</label>
            <input
              type="text"
              className="form-control"
              name="poBox_agent_b"
              value={formData.poBox_agent_b}
              onChange={handleChange}
            />
          </div>
          </div>
          </div>
          <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  NAME OF THE REGISTERED AGENT
  </div>
  <div class="card-body">
          <div className="mb-3">
            <label>Name</label>
            <input
              type="text"
              className="form-control"
              name="name_agent_b"
              value={formData.name_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>BRN</label>
            <input
              type="text"
              className="form-control"
              name="brn_agent_b"
              value={formData.brn_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Date Issued</label>
            <input
              type="date"
              className="form-control"
              name="dateIssued_agent_b"
              value={formData.dateIssued_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Mobile</label>
            <input
              type="text"
              className="form-control"
              name="mobile_agent_b"
              value={formData.mobile_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Email</label>
            <input
              type="email"
              className="form-control"
              name="email_agent_b_form"
              value={formData.email_agent_b_form}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Seller's Agent Form A STR#</label>
            <input
              type="text"
              className="form-control"
              name="sellerFormA_STR_agent_b"
              value={formData.sellerFormA_STR_agent_b}
              onChange={handleChange}
            />
          </div>
          </div>
          </div>
          <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  DECLARATION BY AGENT "B"
    </div>
  <div class="card-body">
    <p>
    I hereby declare, I have read and understood the Real Estate Brokers Code
of Ethics, I have a current signed Seller's Agreement FORM B, I shall
encourage my Buyer as named herein, to submit a reasonable offer for the
stated property and not contact Agent A's Seller nor confer with their client
under no circumstances unless the Agent A has delayed our proposal on
the prescribed FORM with a reasonable reply within 24 hours.

    </p>
    </div>
    </div>

    <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  PART 3. THE COMMISON
  </div>
  <div class="card-body">
    <h5>The following commission split is agreed between the Seller's
Agent A & the Buyers Agent B</h5>
  <div className="mb-3">
            <label>Seller's
            Agent (A)</label>
            <input
              type="text"
              className="form-control"
              name="commission_agent_a"
              value={formData.commission_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Buyer's
            Agent (B)</label>
            <input
              type="text"
              className="form-control"
              name="commission_agent_b"
              value={formData.commission_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Client's Name</label>
            <input
              type="text"
              className="form-control"
              name="clientName"
              value={formData.clientName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Family Name ONLY</label>
            <input
              type="text"
              className="form-control"
              name="familyName"
              value={formData.familyName}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Transfer Fee (Agent A)</label>
            <input
              type="text"
              className="form-control"
              name="transferFee_agent_a"
              value={formData.transferFee_agent_a}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Transfer Fee (Agent B)</label>
            <input
              type="text"
              className="form-control"
              name="transferFee_agent_b"
              value={formData.transferFee_agent_b}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Budget</label>
            <input
              type="text"
              className="form-control"
              name="budget"
              value={formData.budget}
              onChange={handleChange}
            />
          </div>
          <div className="mb-3">
            <label>Has The Buyer had Pre-Finance Approval?</label>
            <select
              className="form-control"
              name="hasPreFinanceApproval"
              value={formData.hasPreFinanceApproval}
              onChange={handleChange}
            >
              <option value="">Select...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
          <div className="mb-3">
            <label>Has The Buyer Contacted The Listing Agent?</label>
            <select
              className="form-control"
              name="hasContactedListingAgent"
              value={formData.hasContactedListingAgent}
              onChange={handleChange}
            >
              <option value="">Select...</option>
              <option value="yes">Yes</option>
              <option value="no">No</option>
            </select>
          </div>
    </div>
    </div>

  

          {/* end of col2 */}
        </div>
        <div className='row'>
        <div class="card">
  <div class="card-header" style={{backgroundColor:'black',color:'white'}}>
  PART 4. SIGNATURES
  </div>
  <div class="card-body">
  <div className='col-md-12'>
  <p>Both Agents are required to co-operate fully, complete this
FORM & BOTH retain a fully signed & stamped copy on file.
RERA DRS is available to both Parties (office Stamps "x" above)
</p>
  </div>

  <div style={{display:'flex',flexDirection:'row'}}>
  <div className='col-md-6'>
        <label>Agent (A) :</label>
        <div className="form-group">
                       
                        <SignatureCanvas
                        // value={formData.signature}
                                ref={signaturePadRefA}
                                penColor='black'
                                canvasProps={{ width: 500, height: 180, className: 'signature-canvas' }}
                                onEnd={handleSignatureEndA} 
                            />
                            {/* <button type="button" onClick={handleSignature}>Save Signature</button> */}
                            <button type="button" onClick={handleClearSignatureA}>Clear Signature</button>
                       
                    </div>
    </div>
    <div className='col-md-6'>
        <label>Agent (B) :</label>
        <div className="form-group">
                      
                        <SignatureCanvas
                        // value={formData.signature}
                                ref={signaturePadRefB}
                                penColor='black'
                                canvasProps={{ width: 500, height: 180, className: 'signature-canvas' }}
                                onEnd={handleSignatureEndB} 
                            />
                            {/* <button type="button" onClick={handleSignature}>Save Signature</button> */}
                            <button type="button" onClick={handleClearSignatureB}>Clear Signature</button>
                       
                    </div>
    </div>
  </div>

 

  
    </div>
    </div>
            
        </div>
      </div>
      <div className='form-group mt-3'>
      <button >Submit</button>
      </div>
    </form>
    </div>
  );
}

export default AgentToAgentAgreementForm;
