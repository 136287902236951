import React, { Component, useState } from "react";
import { Calendar, momentLocalizer } from "react-big-calendar";
import moment from "moment";

import "./Birthday_Calendar_Comp.css";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { updateYearToCurrent } from "../Utilities";
import { Modal, Box, Typography, Button, Card, CardMedia, CardContent, IconButton  } from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import placeHolder1 from "./../Images/placeholder1.png";

const localizer = momentLocalizer(moment);

const EventDetailsModal = ({ eventItem, open, handleClose }) => {
    return (
        <Modal open={open} onClose={handleClose}>
        <Box 
            sx={{ 
                position: 'absolute', 
                top: '50%', 
                left: '50%', 
                transform: 'translate(-50%, -50%)', 
                width: 400, 
                bgcolor: 'background.paper', 
                borderRadius: '8px', 
                boxShadow: 24, 
                p: 2,
                outline: 'none'
            }}
        >
            <Card>
                <IconButton
                    sx={{ 
                        position: 'absolute', 
                        right: 8, 
                        top: 8, 
                        color: 'grey.500' 
                    }}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                {eventItem.profile_image ? (
                    <CardMedia
                        component="img"
                        height="200"
                        image={eventItem.profile_image}
                        alt={eventItem.title}
                    />
                )
                :
                (
                    <CardMedia
                        component="img"
                        height="200"
                        image={placeHolder1}
                        alt={eventItem.title}
                    />
                )
            }
                <CardContent>
                    <Typography variant="h5" component="div">
                        {eventItem.title}
                    </Typography>
                    <Typography sx={{ mt: 2 }} color="text.secondary">
                        {`Wishing to ${eventItem.title} a day filled with happiness and a year filled with joy. Happy birthday!`}
                    </Typography>
                    {/* <Typography sx={{ mt: 1 }} color="text.secondary">
                        End Date: {moment(eventItem.end).format('YYYY-MM-DD')}
                    </Typography> */}
                </CardContent>
            </Card>
        </Box>
    </Modal>
    );
};


const Birthday_Calendar_Comp = ({ events }) =>  {

//   console.log("************ List of Birthdaysss Eventsss ***** ", events )
    const [selectedEvent, setSelectedEvent] = useState(null);
    const [modalOpen, setModalOpen] = useState(false);

    const handleSelectEvent = (eventItem) => {
        setSelectedEvent(eventItem);
        setModalOpen(true);
    };

    const handleClose = () => {
        setModalOpen(false);
        setSelectedEvent(null);
    };



    return (
        <div className="App">
        <Calendar
            localizer={localizer}
            defaultDate={new Date()}
            defaultView="month"
            views={""}
            events={events}
            style={{ height: "100vh" }}
            onSelectEvent={(eventItem) => {
                handleSelectEvent(eventItem)
            }}
        />
        {selectedEvent && (
            <EventDetailsModal 
                eventItem={selectedEvent} 
                open={modalOpen} 
                handleClose={handleClose} 
            />
        )}
        </div>
    );
}

export default Birthday_Calendar_Comp;