import { combineReducers } from "redux";
import { configureStore } from "@reduxjs/toolkit";
import logger from "redux-logger";
import {thunk} from 'redux-thunk';
import { persistStore, persistReducer } from "redux-persist";
import storage from "redux-persist/lib/storage"; // defaults to localStorage for web

// Local 
import loginReducer from "../Pages/Login/sliceLogin";

// add the reducers that need to be persisted
const reducersToPersist = ["login", ];

const persistConfig = {
  key: "root",
  storage,
  whitelist: reducersToPersist,
};

const reducers = combineReducers({
  login: loginReducer,  //login page
});

const persistedReducer = persistReducer(persistConfig, reducers);

const store = configureStore({
    reducer: persistedReducer,
    devTools: process.env.NODE_ENV !== "production",
    middleware: (getDefaultMiddleware) => {
        return [thunk]
    },
});

const persistor = persistStore(store);

export { store, persistor, storage };