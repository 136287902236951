import React, { useEffect, useRef, useState } from 'react'
import Slider from "react-slick";
import PropertyPriceSliderComp from '../../Components/PriceSliderComp';
import { AmountSaperator } from '../../Utilities';
import { GET_LIST_OF_PROPERTIES_FOR_SLIDER } from '../Server/Apis';
import { httpRequest } from '../Server/Axios';
import { CircularProgressbar } from 'react-circular-progressbar';
import './progressstyle.css';
import axios from 'axios';
import { baseUrl, profileImageBaseUrl, propertyImagesUrl } from '../Server/baseUrls';
import { useSelector } from 'react-redux';
import Pusher from 'pusher-js'
import { Avatar } from 'antd';
import FadeLoader from 'react-spinners/FadeLoader'
import ClockLoader from 'react-spinners/ClockLoader'
import { format, parse } from 'date-fns';
import ScreenHeader from '../../Components/ScreenHeader';
import { Container } from '@mui/material';

function Screen() {
  const [sLoader, setLoader] = useState(false);
  const [loading, setLoading] = useState(false)
  const [sError, setError] = useState('');
  const [open, setOpen] = React.useState(false);
  const [date, setDate] = React.useState("");
  const [recentTransactions, setRecentTransactions] = useState([])
  const [propertyData, setPropertyData] = useState([]);
  const [new_listings, setNewListings] = useState([]);
  const [totalSaleValue, setTotalSaleValue] = useState(0)
  const [totalRentValue, setTotalRentValue] = useState(0)

  const [totalSaleValueCurrentMonth, setTotalSaleValueCurrentMonth] = useState(0)
  const [totalRentValueCurrentMonth, setTotalRentValueCurrentMonth] = useState(0)

  const [totalValue, setTotalValue] = useState(0)
  const [totalValuePercentage, setTotalValuePercentage] = useState(0)
  const [topPerformUser, setTopPerformUser] = useState({})

  // const [last_transaction_user, setLastTransactionUser] = useState({});
  // const [totalLastUser,setTotalLasUser] = useState(0)

  const token = useSelector((state) => state.login.token);
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`, // Include the token as a Bearer token
    },
  };
  const settings = {
    dots: false,
    infinite: true,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: true,
    speed: 4000,
    autoplaySpeed: 2000,
    cssEase: "linear",
  };
  useEffect(() => {
    getPropertyListForSlider();
    getScreen()

  }, []);

  const firstRowRef = useRef(null);
  const audioRef = useRef(null);
  useEffect(() => {
    if (firstRowRef.current) {
      firstRowRef.current.classList.add('highlighted');
    }
  }, []);

  useEffect(() => {

    const pusher = new Pusher('1898d49bcd71d4870dc7', {
      cluster: 'ap2'
    });
    const channel = pusher.subscribe('salerent');
    audioRef.current = new Audio('/notification.mp3');
    channel.bind('screen', (newMessage) => {


      const target = 1000000000
      const current_value = newMessage.details.total_value
      const total_value_percentage = (current_value / target) * 100

     


      setTotalValuePercentage(total_value_percentage)
      setRecentTransactions(
        newMessage.details.recent_transactions
      );
      setTotalRentValue(newMessage.details.total_rent)
      setTotalSaleValue(newMessage.details.total_sales)
      setTotalValue(newMessage.details.total_value)
      setTotalSaleValueCurrentMonth(newMessage.details.total_sales_current_month)
      setTotalRentValueCurrentMonth(newMessage.details.total_rent_current_month)
      setTopPerformUser(newMessage.details.topUser)
      try {
        // Attempt to play audio
        if (audioRef.current.paused && newMessage.details.flag == 1) {
          audioRef.current.play();
        }
      } catch (error) {
        // Handle error or fallback to visual notifications
        console.error('Failed to play audio:', error);
      }
    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    }
  }, [recentTransactions])

  useEffect(() => {

    const pusher = new Pusher('1898d49bcd71d4870dc7', {
      cluster: 'ap2'
    });
    const channel = pusher.subscribe('salerent');
    channel.bind('update_listings', (newMessage) => {

      setNewListings(newMessage.details)

    });
    return () => {
      channel.unbind_all();
      channel.unsubscribe();
    }
  }, [new_listings])

  const getScreen = async () => {
    try {
      setLoading(true);
      const url = baseUrl + "/api/screen";
      const response = await axios.get(url, axiosConfig);
      const target = 1000000000
      const current_value = response.data.total_value
      const total_value_percentage = (current_value / target) * 100
      setTotalValuePercentage(total_value_percentage)
      setLoading(false);
      setRecentTransactions(response.data.recent_transactions);
      setNewListings(response.data.new_listings)
      setTotalValue(response.data.total_value)
      setTotalRentValue(response.data.total_rent)
      setTotalSaleValue(response.data.total_sales)
      setTotalSaleValueCurrentMonth(response.data.total_sales_current_month)
      setTotalRentValueCurrentMonth(response.data.total_rent_current_month)

      setTopPerformUser(response.data.topUser)
    } catch (error) {
      setLoading(false);
      console.error("Error:", error);
    }
  }

  const getPropertyListForSlider = async () => {
    setLoader(true);
    const API_LIST_OF_PROPERTY_FOR_SLIDER_CONFIG = GET_LIST_OF_PROPERTIES_FOR_SLIDER();

    try {
      const response = await httpRequest(API_LIST_OF_PROPERTY_FOR_SLIDER_CONFIG);
      // console.log("**** List of Properties Slider ****** ", response);

      if (response.status == 1) {
        setPropertyData(response.data);
        setDate(response.evidence_date);
      }
    } catch (error) {
      setOpen(true);
      setLoader(false);
      setError(error.message);
    }
  }

  const currentDate = new Date();
  const monthNames = ["January", "February", "March", "April", "May", "June",
    "July", "August", "September", "October", "November", "December"
  ];

  const current_date = `${monthNames[currentDate.getMonth()]} ${currentDate.getFullYear()}`;

  const currencyFormat = (num) => {

    return 'AED  ' + num.toFixed(0).replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1,')
  }

  function formatDate(dateString) {
    const newdate = new Date(dateString);
    const options = { day: 'numeric', month: 'short', year: 'numeric' };

    // Formatted date string
    const formattedDate = newdate.toLocaleDateString('en-GB', options);
    return formattedDate

  }
  return (
    <div className=''>
      <ScreenHeader />
      <div className='row mt-3'>
        <Slider {...settings}>
          {
            propertyData.map((e, index) => {
              return (
                <PropertyPriceSliderComp
                  key={index}
                  xProjectName={e.loc}
                  xDevelopedBy={e.emirate}
                  xRate={e.percentage_change}
                  xProgress={e.percentage_change > 0 ? true : false}
                  xDate={date}
                  xMedianPrice={AmountSaperator(e.average_sale_price)}
                />
              )
            })
          }
        </Slider>
      </div>

      <Container maxWidth={'xl'} >
        {loading ?
          <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', marginTop: '1rem', height:'80vh' }}>
            <FadeLoader color="#000000" /></div> :
          <div className='screencontainer'>
            <div className='row ' >
              <div className='col-md-3'>
                <div className="card" style={{ minHeight: '405px' }}  >
                  <div className="card-header" style={{ backgroundColor: 'black', color: 'white' }}>
                    Target AED 1 Billion
                  </div>
                  <div className='progressbar' style={{ marginTop:'3rem' }} >
                    <CircularProgressbar style={{ color: 'black' }} value={totalValuePercentage.toFixed(2)} text={`${totalValuePercentage.toFixed(2)}%`} />
                  </div>
                </div>
              </div>
              <div className='col-md-4'>
                <div className="card" style={{ minHeight: '405px' }} >
                  <div className="card-header" style={{ backgroundColor: 'black', color: 'white' }}>
                    Total Sales/Rent
                  </div>
                  <ul className="list-group list-group-flush" style={{ marginTop:'5rem' }} >
                    <li className="list-group-item" style={{fontSize:'22px',fontWeight:'bold'}}>Total Sale Value  {currencyFormat(parseInt(totalSaleValue))}</li>
                    <li className="list-group-item" style={{fontSize:'16px',fontWeight:'bold'}}>Total Rent Value  {currencyFormat(parseInt(totalRentValue))}</li>
                    <li className="list-group-item" style={{fontSize:'22px',fontWeight:'bold'}}>(YTD) Sales/Rent {currencyFormat(parseInt(totalSaleValue + totalRentValue))}</li>
                    <li className="list-group-item" style={{fontSize:'16px',fontWeight:'bold'}}>(MTD) Sales/Rent Value {currencyFormat(parseInt(totalSaleValueCurrentMonth + totalRentValueCurrentMonth))}</li>
                  </ul>
                </div>
              </div>
              <div className='col-md-5'>
                <div className="card" style={{ minHeight: '140px' }}>
                  <div className="card-header" style={{ backgroundColor: 'black', color: 'white' }}>
                    Top Performer of the Month | {current_date}
                  </div>
                  {!topPerformUser ? <div style={{ display: 'flex', justifyContent: 'center', alignItems: 'center', padding: '12%' }}>
                    <ClockLoader size={192} color="#000000" /></div> :
                    <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center',  }}>
                      <img src={profileImageBaseUrl + "/" + topPerformUser.profile_image} style={{ height: '250px', width: '220px' }} />
                      <h5 className='mt-3'>{topPerformUser.name}</h5>
                      <h5 style={{fontWeight:'bold'}}>Total value:  {currencyFormat(parseInt(topPerformUser.total_deal_value))}</h5>
                    </div>}


                </div>
              </div>
            </div>

            <div className='row '>
              <div className='col-md-7'>
                <div className="card"  >
                  <div className="card-header" style={{ backgroundColor: 'black', color: 'white' }}>
                    Recent Transactions
                  </div>
                  <table className="table table-striped">
                    <thead>
                      <tr>

                        <th scope="col">Agent </th>
                        <th scope="col">Project</th>
                        <th scope="col">Type</th>
                        <th scope="col">Value</th>
                        <th scope="col">Date</th>
                      </tr>
                    </thead>
                    <tbody>
                      {recentTransactions.map((recent_transactions, index) => (
                        <tr key={recent_transactions.id}   >

                          <td className={index == 0 ? 'active-row' : ''} >
                            {/* <Avatar size={{
                xs: 24,
                sm: 32,
                md: 40,
                lg: 44,
                xl: 50,
                xxl: 50
            }} src={ profileImageBaseUrl +"/"+recent_transactions.profile_image} /> */}

                            {recent_transactions.agents.map((agent, index) => (
                              <div key={index}>{agent.name}</div>
                            ))}


                            {/* <h5>{recent_transactions.agentName}</h5> */}
                          </td>
                          <td className={index == 0 ? 'active-row' : ''}>{recent_transactions.project}</td>
                          <td className={index == 0 ? 'active-row' : ''}>{recent_transactions.type}</td>
                          <td className={index == 0 ? 'active-row' : ''}> {currencyFormat(parseInt(recent_transactions.dealValue))}</td>
                          <td className={index == 0 ? 'active-row' : ''}> {formatDate(recent_transactions.saleDate)}</td>
                        </tr>
                      ))}


                    </tbody>
                  </table>
                </div>
              </div>
              <div className='col-md-5'>
                <div className="card" >
                  <div className="card-header" style={{ backgroundColor: 'black', color: 'white' }}>
                    New Launches
                  </div>
                  <table className="table table-bordered">
                    <thead>
                      <tr>
                        <th style={{ width: '200px' }}>Project</th>
                        <th style={{ width: '200px' }}>Starting price</th>

                        <th>Payment plan</th>
                        <th>Developer</th>
                      </tr>
                    </thead>
                    <tbody>
                      {new_listings.length < 1 && <tr>
                        <td colSpan={4}>No data</td>
                      </tr>}

                      {new_listings.map((n_l, index) => (
                        <tr key={index}>
                          <td>{n_l.project}</td>
                          <td >{currencyFormat(parseInt(n_l.starting_price))}</td>
                          <td>{n_l.payment_plan}</td>
                          <td>{n_l.developer}</td>
                        </tr>
                      ))}



                    </tbody>
                  </table>
                </div>
              </div>
            </div>
          </div>
        }
      </Container>
    </div>
  )
}

export default Screen
