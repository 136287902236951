import React, { useRef, useState } from 'react';
import axios from 'axios';
import './kyc.css'
import { baseUrl } from '../../Server/baseUrls';
import SignatureCanvas from 'react-signature-canvas';
import { Snackbar } from '@material-ui/core';
import {
    Container,
    Typography,
    Button,
    TextField,
    // Select,
    MenuItem,
    Grid,
    Box,
    // Modal,
    Alert
  } from '@mui/material';
const KYCForm = () => {
    const [signatureData, setSignatureData] = useState('');
    const signaturePadRef = useRef(null);
    const [showToast, setShowToast] = React.useState(false);
    const [loading,setLoading] = useState(false)
    const [formData, setFormData] = useState({
        client_status: '',
        ownership: '',
        title: '',
        surname: '',
        forenames: '',
        nationality: '',
        gender: '',
        second_nationality: '',
        dob: '',
        birth_country: '',
        residence_country: '',
        address_line1: '',
        address_line2: '',
        city: '',
        postal_code: '',
        state_province: '',
        country: '',
        telephone_res: '',
        telephone_off: '',
        mobile_personal: '',
        mobile_business: '',
        personal_email: '',
        business_email: '',
        passport_no: '',
        passport_type: '',
        passport_issue_date: '',
        passport_expiry_date: '',
        passport_place_issue: '',
        passport_country_issue: '',
        national_id_no: '',
        national_id_expiry_date: '',
        issuing_authority: '',
        national_id_country: '',
        pep: '',
        pep_position: '',
        related_pep: '',
        related_pep_name: '',
        related_pep_position: '',
        related_pep_country: '',
        ubo: '',
        ubo_name: '',
        source_of_funds: [],
        other_source_of_funds: '',
        signature: '',
        date: '',
    });

    const handleChange = (e) => {
        const { name, value, type, checked } = e.target;
        if (type === 'checkbox') {
            setFormData(prevData => ({
                ...prevData,
                [name]: checked ? [...prevData[name], value] : prevData[name].filter(item => item !== value)
            }));
        } else {
            setFormData(prevData => ({
                ...prevData,
                [name]: value
            }));
        }
    };
    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
    
        setShowToast(false);
      };
    const handleSignature = () => {
        if (signaturePadRef.current) {
            setSignatureData(signaturePadRef.current.toDataURL());
            setFormData(prevData => ({
                ...prevData,
                signature: signaturePadRef.current.toDataURL()
            }));
        }
    };

    const handleSignatureEnd = () => {
        if (signaturePadRef.current) {
            setFormData(prevData => ({
                ...prevData,
                signature: signaturePadRef.current.toDataURL()
            }));
        }
    };

    const handleClearSignature = () => {
        if (signaturePadRef.current) {
            signaturePadRef.current.clear();
            setFormData(prevData => ({
                ...prevData,
                signature: ''
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            const response = await axios.post(baseUrl+'/api/kyc', formData);

            if(response.data.success){

                setShowToast(true)
            //alert('Form submitted successfully');
            setFormData({ client_status: '',
                ownership: '',
                title: '',
                surname: '',
                forenames: '',
                nationality: '',
                gender: '',
                second_nationality: '',
                dob: '',
                birth_country: '',
                residence_country: '',
                address_line1: '',
                address_line2: '',
                city: '',
                postal_code: '',
                state_province: '',
                country: '',
                telephone_res: '',
                telephone_off: '',
                mobile_personal: '',
                mobile_business: '',
                personal_email: '',
                business_email: '',
                passport_no: '',
                passport_type: '',
                passport_issue_date: '',
                passport_expiry_date: '',
                passport_place_issue: '',
                passport_country_issue: '',
                national_id_no: '',
                national_id_expiry_date: '',
                issuing_authority: '',
                national_id_country: '',
                pep: '',
                pep_position: '',
                related_pep: '',
                related_pep_name: '',
                related_pep_position: '',
                related_pep_country: '',
                ubo: '',
                ubo_name: '',
                source_of_funds: [],
                other_source_of_funds: '',
                signature: '',
                date: '',})
            // console.log(response.data);
            }
        } catch (error) {
            setShowToast(false)
            console.error('Error submitting form', error);
            alert('Error submitting form');
        }
    };

    return (
        <div>
             <Snackbar
        open={showToast}
        onClose={handleCloseToast}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
        Form submitted successfully
        </Alert>
      </Snackbar>
           <div className="header">
                <img src="http://kestates.us.tempcloudsite.com/kyc/web_logo_white.jpg" alt="Company Logo" />
                <h1>KYC Individual Form</h1>
            </div>  
        <div className="containerf">
           
            <form onSubmit={handleSubmit}>
                <div className="section-title">OFFICE USE ONLY</div>
                <div className="form-row">
                    <div className="form-group">
                        <label>Client Status:</label>
                        <div className="checkbox-group">
                            <input type="radio" name="client_status" value="New" checked={formData.client_status === 'New'} onChange={handleChange} /> New
                            <input type="radio" name="client_status" value="Existing" checked={formData.client_status === 'Existing'} onChange={handleChange} /> Existing
                        </div>
                    </div>
                    <div className="form-group">
                        <label>Proposed Ownership/Tenancy:</label>
                        <div className="checkbox-group">
                            <input type="radio" name="ownership" value="Single" checked={formData.ownership === 'Single'} onChange={handleChange} /> Single
                            <input type="radio" name="ownership" value="Joint" checked={formData.ownership === 'Joint'} onChange={handleChange} /> Joint
                        </div>
                    </div>
                </div>
                
                <div className="section-title">PERSONAL INFORMATION</div>
                <div className="form-row">
                    <div className="form-group">
                        <label>Title:</label>
                        <select name="title" value={formData.title} onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="Mr.">Mr.</option>
                            <option value="Mrs.">Mrs.</option>
                            <option value="Ms.">Ms.</option>
                            <option value="Dr.">Dr.</option>
                            <option value="Other">Other</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>First Name:</label>
                        <input type="text" name="surname" value={formData.surname} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Last Name:</label>
                        <input type="text" name="forenames" value={formData.forenames} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Nationality:</label>
                        <input type="text" name="nationality" value={formData.nationality} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Gender:</label>
                        <div className="checkbox-group">
                            <input type="radio" name="gender" value="Male" checked={formData.gender === 'Male'} onChange={handleChange} /> Male
                            <input type="radio" name="gender" value="Female" checked={formData.gender === 'Female'} onChange={handleChange} /> Female
                        </div>
                    </div>
                    <div className="form-group">
                        <label>2nd Nationality:</label>
                        <input type="text" name="second_nationality" value={formData.second_nationality} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Date of Birth:</label>
                        <input type="date" name="dob" value={formData.dob} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Country of Birth:</label>
                        <input type="text" name="birth_country" value={formData.birth_country} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Country of Residence:</label>
                        <input type="text" name="residence_country" value={formData.residence_country} onChange={handleChange} required />
                    </div>
                </div>
                
                <div className="section-title">ADDRESS DETAILS</div>
                <div className="form-row">
                    <div className="form-group full-width">
                        <label>Address (Line 1):</label>
                        <input type="text" name="address_line1" value={formData.address_line1} onChange={handleChange} required />
                    </div>
                    <div className="form-group full-width">
                        <label>Address (Line 2):</label>
                        <input type="text" name="address_line2" value={formData.address_line2} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>City:</label>
                        <input type="text" name="city" value={formData.city} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>PO Box/Postal Code:</label>
                        <input type="text" name="postal_code" value={formData.postal_code} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>State/Province:</label>
                        <input type="text" name="state_province" value={formData.state_province} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Country:</label>
                        <input type="text" name="country" value={formData.country} onChange={handleChange} required />
                    </div>
                </div>
                
                <div className="section-title">CONTACT DETAILS</div>
                <div className="form-row">
                    <div className="form-group">
                        <label>Telephone (Residential):</label>
                        <input type="tel" name="telephone_res" value={formData.telephone_res} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Telephone (Office):</label>
                        <input type="tel" name="telephone_off" value={formData.telephone_off} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Mobile (Personal):</label>
                        <input type="tel" name="mobile_personal" value={formData.mobile_personal} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Mobile (Business):</label>
                        <input type="tel" name="mobile_business" value={formData.mobile_business} onChange={handleChange} />
                    </div>
                    <div className="form-group">
                        <label>Personal Email:</label>
                        <input type="email" name="personal_email" value={formData.personal_email} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Business Email:</label>
                        <input type="email" name="business_email" value={formData.business_email} onChange={handleChange} />
                    </div>
                </div>
                
                <div className="section-title">IDENTIFICATION DETAILS</div>
                <div className="form-row">
                    <div className="form-group">
                        <label>Passport Number:</label>
                        <input type="text" name="passport_no" value={formData.passport_no} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Passport Type:</label>
                        <select name="passport_type" value={formData.passport_type} onChange={handleChange}>
                            <option value="">Select</option>
                            <option value="Ordinary">Ordinary</option>
                            <option value="Diplomatic">Diplomatic</option>
                            <option value="Service">Service</option>
                            <option value="Official">Official</option>
                        </select>
                    </div>
                    <div className="form-group">
                        <label>Passport Issue Date:</label>
                        <input type="date" name="passport_issue_date" value={formData.passport_issue_date} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Passport Expiry Date:</label>
                        <input type="date" name="passport_expiry_date" value={formData.passport_expiry_date} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Place of Issue:</label>
                        <input type="text" name="passport_place_issue" value={formData.passport_place_issue} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Country of Issue:</label>
                        <input type="text" name="passport_country_issue" value={formData.passport_country_issue} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>National ID Number:</label>
                        <input type="text" name="national_id_no" value={formData.national_id_no} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>National ID Expiry Date:</label>
                        <input type="date" name="national_id_expiry_date" value={formData.national_id_expiry_date} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Issuing Authority:</label>
                        <input type="text" name="issuing_authority" value={formData.issuing_authority} onChange={handleChange} required />
                    </div>
                    <div className="form-group">
                        <label>Country of National ID:</label>
                        <input type="text" name="national_id_country" value={formData.national_id_country} onChange={handleChange} required />
                    </div>
                </div>
                
                <div className="section-title">POLITICALLY EXPOSED PERSON (PEP)</div>
                <div className="form-row">
                    <div className="form-group">
                        <label>Are you a Politically Exposed Person (PEP)?</label>
                        <div className="checkbox-group">
                            <input type="radio" name="pep" value="Yes" checked={formData.pep === 'Yes'} onChange={handleChange} /> Yes
                            <input type="radio" name="pep" value="No" checked={formData.pep === 'No'} onChange={handleChange} /> No
                        </div>
                    </div>
                    {formData.pep === 'Yes' && (
                        <>
                            <div className="form-group">
                                <label>PEP Position:</label>
                                <input type="text" name="pep_position" value={formData.pep_position} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Related PEP:</label>
                                <input type="text" name="related_pep" value={formData.related_pep} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Related PEP Name:</label>
                                <input type="text" name="related_pep_name" value={formData.related_pep_name} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Related PEP Position:</label>
                                <input type="text" name="related_pep_position" value={formData.related_pep_position} onChange={handleChange} />
                            </div>
                            <div className="form-group">
                                <label>Related PEP Country:</label>
                                <input type="text" name="related_pep_country" value={formData.related_pep_country} onChange={handleChange} />
                            </div>
                        </>
                    )}
                </div>
                
                <div className="section-title">UBO (ULTIMATE BENEFICIAL OWNER)</div>
                <div className="form-row">
                    <div className="form-group">
                        <label>Are you the Ultimate Beneficial Owner (UBO)?</label>
                        <div className="checkbox-group">
                            <input type="radio" name="ubo" value="Yes" checked={formData.ubo === 'Yes'} onChange={handleChange} /> Yes
                            <input type="radio" name="ubo" value="No" checked={formData.ubo === 'No'} onChange={handleChange} /> No
                        </div>
                    </div>
                    {formData.ubo === 'No' && (
                        <>
                            <div className="form-group">
                                <label>UBO Name:</label>
                                <input type="text" name="ubo_name" value={formData.ubo_name} onChange={handleChange} />
                            </div>
                        </>
                    )}
                </div>
                
                <div className="section-title">SOURCE OF FUNDS</div>
                <div className="form-row">
                    <div className="form-group">
                        <label>Source of Funds:</label>
                        <div className="checkbox-group">
                            <input type="checkbox" name="source_of_funds" value="Salary" checked={formData.source_of_funds.includes('Salary')} onChange={handleChange} /> Salary
                            <input type="checkbox" name="source_of_funds" value="Business" checked={formData.source_of_funds.includes('Business')} onChange={handleChange} /> Business
                            <input type="checkbox" name="source_of_funds" value="Inheritance" checked={formData.source_of_funds.includes('Inheritance')} onChange={handleChange} /> Gift/Inheritance
                            <input type="checkbox" name="source_of_funds" value="Investment" checked={formData.source_of_funds.includes('Investment')} onChange={handleChange} /> Investment
                            <input type="checkbox" name="source_of_funds" value="Rental" checked={formData.source_of_funds.includes('Rental')} onChange={handleChange} /> Rental
                            <input type="checkbox" name="source_of_funds" value="FamilySupport" checked={formData.source_of_funds.includes('FamilySupport')} onChange={handleChange} /> Family Support
                            <input type="checkbox" name="source_of_funds" value="SaleofProperty" checked={formData.source_of_funds.includes('SaleofProperty')} onChange={handleChange} /> Sale of Property
                            <input type="checkbox" name="source_of_funds" value="SaleofCompanyBusiness" checked={formData.source_of_funds.includes('SaleofCompanyBusiness')} onChange={handleChange} /> Sale of Company/Business

                            <input type="checkbox" name="source_of_funds" value="Other" checked={formData.source_of_funds.includes('Other')} onChange={handleChange} /> Other
                        </div>
                    </div>
                    
                </div>
                <div className='for-row'>
                {formData.source_of_funds.includes('Other') && (
                        <div className="form-group">
                            <label>Other Source of Funds:</label>
                            <input type="text" name="other_source_of_funds" value={formData.other_source_of_funds} onChange={handleChange} />
                        </div>
                    )}
                </div>
                <div className="section-title">DECLARATION</div>
                <ul>
                    <li>I hereby acknowledge and declare that all the information provided in this Know Your Customer (KYC) form is true and complete and I
                    undertake to inform you of any changes. </li>
                    <li>I hereby give unconditional and irrevocable written consent to by  <u>K ESTATES LLC</u> , its subsidiaries, agents and authorized staff
                    and any third-party service providers for disclosure, sharing, usage, processing and searching of my information and records as required
                    by UAE Anti Money Laundering and Combatting of Terrorism Laws. 
                    </li>
                    <li>
                    I agree that any duplication and any copy, photocopy, electronic data, or facsimile which have been made as a copy from this original
                    consent by means of photocopying, image scanning, or recording in whatever forms shall be deemed as evidence of consent with the
                    same effect as its original. 
                    </li>
                    <li>
                    I shall indemnify and hold <u> K ESTATES LLC </u>safe from any claims howsoever arising from as a result of such sharing, searching,
                    usage, processing or disclosure of account information and data. 
                    </li>
                </ul>
                <div className="form-row">
                    <div className="form-group">
                        <label>Signature:</label>
                        <SignatureCanvas
                        // value={formData.signature}
                                ref={signaturePadRef}
                                penColor='black'
                                canvasProps={{ width: 500, height: 200, className: 'signature-canvas' }}
                                onEnd={handleSignatureEnd} 
                            />
                            {/* <button type="button" onClick={handleSignature}>Save Signature</button> */}
                            <button type="button" onClick={handleClearSignature}>Clear Signature</button>
                       
                    </div>
                
                    <div className="form-group">
                        <label>Date:</label>
                        <input type="date" name="date" value={formData.date} onChange={handleChange} required />
                    </div>
                </div>
                
                <button type="submit">Submit</button>
            </form>
        </div>
        </div>
    );
};

export default KYCForm;
