import React, { useEffect, useState } from 'react';
import { 
    Button,
    Input,
    Typography,
    Avatar,
    Grid,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from '@mui/material';
import {EditOutlined} from '@ant-design/icons'
import EditIcon from '@mui/icons-material/Edit';
// Local
import "./AddClientComp.css";
import { user } from '../LocalDB';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BaseImageUrl } from '../Components/BaseImageUrl';
import { BaseUrl } from '../Components/BaseUrl';
import { baseUrl } from '../Pages/Server/baseUrls';
import { MultiSelect } from "react-multi-select-component";

const UpdateClient = ({id,xCLoseUpdateModal,updateClientData,countries,locations
    // xImage, xName, xPhoneNumber, xEmail, xDob, xStatus, xAddress, xCountry, xDocument,
}) => {
    // const [image, setImage] = useState( xImage ? xImage : null);
    // const [name, setName] = useState( xName ? xName : '');
    // const [phoneNumber, setPhoneNumber] = useState( xPhoneNumber ? xPhoneNumber : '');
    // const [email, setEmail] = useState( xEmail ? xEmail : '');
    // const [dob, setDob] = useState( xDob ? xDob : '');
    // const [status, setStatus] = useState( xStatus ? xStatus : 'active');
    // const [address, setAddress] = useState( xAddress ? xAddress : '');
    // const [country, setCountry] = useState( xCountry ? xCountry : '');
    // const [document, setDocument] = useState( xDocument ? xDocument : null);

    const [image, setImage] = useState(null);
    const [showimage, setShowImage] = useState(null);
    const [name, setName] = useState('');
    const [phoneNumber, setPhoneNumber] = useState('');
    const [email, setEmail] = useState('');
    const [password, setPassword] = useState('');

    const [dob, setDob] = useState('');
    const [status, setStatus] = useState('Active');
    const [address, setAddress] = useState('');
    const [country, setCountry] = useState('');
    const [selectedArea, setSelectedArea] = useState([]);

    // const [countries,setCountries] = useState([])
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});

    const token = useSelector((state) => state.login.token);

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
            'Content-Type': 'multipart/form-data'
        },
    };

    useEffect(() =>{
            setName(updateClientData.name)
            setShowImage(updateClientData.profile_image)
            setPhoneNumber(updateClientData.phone)
            setEmail(updateClientData.email)
            setStatus(updateClientData.active)
            setCountry(updateClientData.country_id)
            setAddress(updateClientData.address)
            setDob(updateClientData.dob)
            setSelectedArea(updateClientData.areas)
    //    axios.get(baseUrl+"/api/owners/"+id,axiosConfig)
    //    .then((res) =>{
    //     if(res.data.success){
    //         const user = res.data.user
    //         setName(user.name)
    //         setShowImage(user.profile_image)
    //         setPhoneNumber(user.phone)
    //         setEmail(user.email)
    //         setStatus(user.active)
    //         setCountry(user.country_id)
    //         setAddress(user.address)
    //         setDob(user.dob)
    //     }
        
    //    })

    //    axios.get(baseUrl+"/api/countries",axiosConfig)
    //    .then((res) =>{
    //      if(res.data.success){
    //          setCountries(res.data.countries)
    //      }
    //    }).catch((err) =>{
    //     console.log("err",err);
    // })
    },[updateClientData])
  
    const handleNameChange = (event) => {
      setName(event.target.value);
    };

    const handlePhoneNumberChange = (event) => {
        setPhoneNumber(event.target.value);
      };
  
    const handleEmailChange = (event) => {
      setEmail(event.target.value);
    };
  
    const handleDobChange = (event) => {
      setDob(event.target.value);
    };
  
    const handleStatusChange = (event) => {
      setStatus(event.target.value);
    };
  
    const handleAddressChange = (event) => {
      setAddress(event.target.value);
    };
  
    const handleCountryChange = (event) => {
      setCountry(event.target.value);
    };

    
    const validate = () => {
        let input = inputs;
        let errors = {};
        let isValid = true;
        if (!name) {
          isValid = false;
          errors["name"] = "Name is required";
        }
     
        if (!phoneNumber) {
            isValid = false;
            errors["phoneNumber"] = "Phone Number is required";
          } 

          if (!country) {
            isValid = false;
            errors["country"] = "Country is required";
          }

          

          if (!email) {
            isValid = false;
            errors["email"] = "Email is required";
            
          } else if (!/\S+@\S+\.\S+/.test(email)) errors["email"] = 'Email is not valid';
       

        setErrors(errors)
        return isValid;
      };
  
    const handleProfileUpdate = () => {
        
     if(validate()){
        const formData = new FormData();
        formData.append('id',id)
        formData.append('name', name);
        formData.append('phone', phoneNumber);
        formData.append('email', email);
        formData.append('password', password);
        formData.append('dob', dob);
        formData.append('status', status);
        formData.append('address', address);
        formData.append('country', country);
        formData.append('image', image); 
        

        formData.append('old_image', showimage);
        selectedArea.forEach((file, index) => {
            formData.append(`areas[${index}]`, file.value);
        });
      
        
        axios.post(baseUrl+"/api/updateagent", formData, {
            headers: {
                'Content-Type': 'multipart/form-data', // Important: set content type to multipart/form-data
                ...axiosConfig.headers // Include any other headers
            }
        })
        .then((res) => {
            xCLoseUpdateModal()
            console.log("res server", res.data);
        })
        .catch((error) => {
            console.error("Error:", error);
        });
       
    }else{
        return
    }
    };

    // Function to handle file input change
    const handleFileChange = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onloadend = () => {
        setImage(reader.result);
        };

        if (file) {
        reader.readAsDataURL(file);
        }
    };

    // Function to handle image update
    const handleImageUpdate = () => {
        // Implement your image update logic here, for example, send the image to the server
        console.log('Image Updated:', image);
    };

  

    return(
        <div style={{ width:"100%", display:'flex', alignItems:"center" , justifyContent:"center"}} >
            <div className='addClient-container' >
                {/* <h1> User Acount </h1> */}
                {/* <Typography variant="h5" style={{ fontFamily:'Montserrat'}} >{user.name}</Typography>
                <Typography variant="caption" style={{ fontFamily:'Montserrat'}} >{user.address}</Typography> */}

                <Grid container spacing={2} alignItems={'center'} flexDirection={'column'} >
                    <Grid item className='change-profile' >
                        <div style={{ alignItems:"center" }} >
                            {
                            image && image !==null?<Avatar alt="Profile Picture" src={image} style={{ width:'9rem', height:'9rem' }} />:
                            <Avatar alt="Profile Picture" src={BaseImageUrl+"profile_images/"+showimage} style={{ width:'9rem', height:'9rem' }} />
}
                        
                            
                            <Grid item >
                                <Input
                                    type="file"
                                    accept="image/*"
                                    onChange={handleFileChange}
                                    style={{ display: 'none' }}
                                    id="upload-button"
                                />
                                <label htmlFor="upload-button">
                                    <Button variant="outlined" color="info" component="span" size='small' endIcon={<EditIcon />} style={{ marginTop:'1rem', marginLeft:'1rem' }}>
                                        change
                                    </Button>

                                </label>
                            </Grid>
                        </div>
                        {/* <div style={{ marginLeft: '1rem' }}>
                            <Typography variant="body1" style={{ fontFamily:'Montserrat'}} >{user.name}</Typography>
                            <Typography variant="body1" style={{ fontFamily:'Montserrat'}} >{user.address}</Typography>
                        </div> */}
                    </Grid>
                    
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Name"
                            value={name}
                            onChange={handleNameChange}
                        />
                            <div className="text-danger">
                          {errors.name}
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Email"
                            value={email}
                            onChange={handleEmailChange}
                        />
                            <div className="text-danger">
                          {errors.email}
                        </div>
                    </Grid>
                </Grid>
               
                <Grid container spacing={2} style={{ marginTop: '2rem',display:'flex',flexDirection:'row' }}>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="New password"
                            value={password}
                            onChange={(e) =>setPassword(e.target.value)}
                        />
                           <div className="text-danger">
                          {errors.password}
                        </div>
                    </Grid>
                    
                    </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="Phone Number"
                                value={phoneNumber}
                                onChange={handlePhoneNumberChange}
                            />
                                <div className="text-danger">
                          {errors.phoneNumber}
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                    <MultiSelect
                        options={locations}
                        value={selectedArea}
                        onChange={setSelectedArea}
                        labelledBy="Select Areas"
                        overrideStrings={{
                            "selectSomeItems": "Select Areas", // Custom placeholder text
                            allItemsAreSelected: "All Areas Selected", // Text when all items are selected

                        }}
                   

                    />
                    </Grid>
                </Grid> 

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <Typography  >
                            Date of Birth
                        </Typography>
                        <TextField
                            fullWidth
                            type="date"
                            // label="Change DOB"
                            value={dob}
                            onChange={handleDobChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <Typography >
                            Status
                        </Typography>
                        <RadioGroup value={status} onChange={handleStatusChange} style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:"flex-start" }} >
                            <FormControlLabel value="Active" control={<Radio />} label="Active" />
                            <FormControlLabel value="Inactive" control={<Radio />} label="Inactive" />
                        </RadioGroup>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Address"
                            value={address}
                            onChange={handleAddressChange}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <FormControl fullWidth>
                            <InputLabel id="demo-simple-select-label">Select Country</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                fullWidth
                                label="Select Country"
                                value={country}
                                onChange={handleCountryChange}
                            >
                                {countries.map((country,index)=>(
                                    <MenuItem key={index} value={country.id}>{country.name}</MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                        <div className="text-danger">
                          {errors.country}
                        </div>
                    </Grid>

                </Grid>
     

                <Grid item xs={12} style={{ marginTop: '2rem' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleProfileUpdate}
                    >
                        Update Agent
                    </Button>
                </Grid>
            </div>
        </div>
    )
}

export default UpdateClient;