import React, { useEffect, useState } from "react";
import ClearIcon from '@mui/icons-material/Clear';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import SaveIcon from '@mui/icons-material/Save';
import axios from "axios";
import { BaseUrl } from "../Components/BaseUrl";
import { useSelector } from "react-redux";
import BeatLoader from "react-spinners/BeatLoader";
import CurrencyInput from 'react-currency-input-field';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import { baseUrl } from "../Pages/Server/baseUrls";


function TableRows({ rows, tableRowRemove, onValUpdate, errors }) {
  return rows.map((rowsData, index) => {
    const { date, number_of_installment, paymnet_perccentage, amount, installment_details } = rowsData;

    const handleValueChange = (index,value, name) => {

    console.log("value",value);
      
      const syntheticEvent = {
        target: {
          name: 'amount',
          value: value,
        },
      };
    onValUpdate(index,syntheticEvent)
    }

    return (
      <tr key={index}>
        <td>
          <input
            type="date"
            required
            value={date}
            onChange={(event) => onValUpdate(index, event)}
            name="date"
            className="form-control"
          />
          {errors[index]?.date && <span className="text-danger">Date is required</span>}
        </td>
        <td>
          <input
            type="text"
            required
            value={number_of_installment}
            onChange={(event) => onValUpdate(index, event)}
            name="number_of_installment"
            className="form-control"
          />
          {errors[index]?.number_of_installment && <span className="text-danger">Number of installment is required</span>}
        </td>
        <td>
          <input
            type="text"
            required
            value={paymnet_perccentage}
            onChange={(event) => onValUpdate(index, event)}
            name="paymnet_perccentage"
            className="form-control"
          />
          {errors[index]?.paymnet_perccentage && <span className="text-danger">Payment percentage is required</span>}
        </td>
        <td>
          {/* <input
            type="text"
            value={amount}
            onChange={(event) => onValUpdate(index, event)}
            name="amount"
            className="form-control"
          /> */}
           <CurrencyInput
                        style={{width:'100%',padding: '7px',boxSizing: 'border-box',border: '1px solid #ccc', borderRadius: '4px',
                        fontSize: '16px',  transition: 'border-color 0.3s'}}
                        id="input-example"
                        name="amount"
                        // label="Price"
                        // placeholder="Price"
                        value={amount}
                        decimalsLimit={2}
                        onValueChange={(value,name)=>handleValueChange(index,value,name)}
                       
                        />
          {errors[index]?.amount && <span className="text-danger">Amount is required</span>}
        </td>
        <td>
          <input
            type="text"
            value={installment_details}
            onChange={(event) => onValUpdate(index, event)}
            name="installment_details"
            className="form-control"
          />
          {errors[index]?.installment_details && <span className="text-danger">Installment details are required</span>}
        </td>
        <td>
          <RemoveCircleOutlineIcon onClick={() => tableRowRemove(index)} onMouseOver={(e) => {
            e.currentTarget.style.cursor = 'pointer'; // Change cursor on hover
          }} style={{ marginBottom: '1rem', marginLeft: '1rem' }} />
        </td>
      </tr>
    );
  });
}

const PaymentPlan = ({ property_id, xCLosePayment, payment_plans }) => {
  const token = useSelector((state) => state.login.token);
  const axiosConfig = {
    headers: {
      Authorization: `Bearer ${token}`, // Include the token as a Bearer token
    },
  };

  const [rows, initRow] = useState([]);
  const [isEdit, setIsEdit] = useState(false);
  const [loading, setLoading] = useState(false);
  const [errors, setErrors] = useState([]);

  useEffect(() => {
    if (payment_plans && payment_plans.length > 0) {
      initRow(payment_plans);
    } else {
      initRow([]);
    }
    setIsEdit(false);
  }, [payment_plans]);

  const addRowTable = () => {
    const data = {
      date: "",
      number_of_installment: "",
      paymnet_perccentage: "",
      amount: "",
      installment_details: ""
    };
    initRow([...rows, data]);
  };

  const tableRowRemove = (index) => {
    const dataRow = [...rows];
    dataRow.splice(index, 1);
    initRow(dataRow);
  };

  const onValUpdate = (i, event) => {
    const { name, value } = event.target;
    const data = [...rows];
    data[i][name] = value;
    initRow(data);
  };

  


  const validateRows = () => {
    const newErrors = rows.map(row => {
      const rowErrors = {};
      
      if (!row.number_of_installment) rowErrors.number_of_installment = "Number of installment is required";
      if (!row.paymnet_perccentage) rowErrors.paymnet_perccentage = "Payment percentage is required";
      if (!row.amount) rowErrors.amount = "Amount is required";
      if (!row.installment_details) rowErrors.installment_details = "Installment details are required";
      return rowErrors;
    });
    setErrors(newErrors);
    return newErrors.every(rowErrors => Object.keys(rowErrors).length === 0);
  };

  const handleSubmit = () => {
    
    if (validateRows()) {
      setLoading(true);
      const data = { property_id: property_id, payments: rows };
      axios.post(baseUrl + "/api/paymentplan", data, axiosConfig)
        .then((res) => {
          if (res.data.success) {
            setLoading(false);
            xCLosePayment();
          }
        })
        .catch((error) => {
          setLoading(false);
          console.error("Error:", error);
        });
    }
  };

  const handleCancel = () => {
    setIsEdit(false);
    if (payment_plans && payment_plans.length > 0) {
      initRow(payment_plans);
    } else {
      initRow([]);
    }
  };

  return (
    <>
      <table className="table table-bordered">
        <thead>
          <tr>
            <th>Date</th>
            <th>Number of installment</th>
            <th>Payment percentage</th>
            <th>Amount</th>
            <th>Installment details</th>
            <th>
              {!isEdit ? (
                <button className="btn btn-primary" onClick={() => setIsEdit(true)}><ModeEditOutlineIcon />Edit Plan</button>
              ) : (
                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                  <button className="btn btn-danger btn-sm" onClick={handleCancel}><CloseIcon /> Cancel</button>
                  <button className="btn btn-sm btn-info ml-2" onClick={addRowTable}><AddIcon/> Add row</button>
                </div>
              )}
            </th>
          </tr>
        </thead>
        <tbody>
          {!isEdit ? (
            payment_plans.map((p_p, index) => (
              <tr key={index}>
                <td>{p_p.date?p_p.date:"-"}</td>
                <td>{p_p.number_of_installment}</td>
                <td>{p_p.paymnet_perccentage}</td>
                <td>{p_p.amount &&
                  p_p.amount.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ",") + ' AED'
                }</td>
                <td>{p_p.installment_details}</td>
              </tr>
            ))
          ) : (
            rows.length < 1 ? (
              <tr style={{ alignContent: 'center', alignItems: 'center' }}>
                <td colSpan={6}>
                  No Payment plan
                </td>
              </tr>
            ) : (
              <TableRows
                rows={rows}
                tableRowRemove={tableRowRemove}
                onValUpdate={onValUpdate}
                errors={errors}
              />
            )
          )}
        </tbody>
      </table>
      <div className="row">
        <div className="form-group" style={{ margin: '20px' }}>
          {rows && rows.length && isEdit > 0 ? (
            <button className="btn btn-primary" onClick={handleSubmit} disabled={loading}>
              {loading ? (
                <>
                  <BeatLoader color="white" size={5} />
                  Processing
                </>
              ) : (
                <>
                  <SaveIcon /> Save Payment plan
                </>
              )}
            </button>
          ) : null}
        </div>
      </div>
    </>
  );
}

export default PaymentPlan;
