import React, { useEffect, useState } from 'react';
import { 
    Container,
    Typography,
    Button,
    TextField,
    Select,
    MenuItem,
    Grid,
    Box,
    Alert
} from '@mui/material';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import AddIcon from '@mui/icons-material/Add';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
import DeleteOutlineIcon from '@mui/icons-material/DeleteOutline';
import ModeEditOutlineIcon from '@mui/icons-material/ModeEditOutline';
import PreviewIcon from '@mui/icons-material/Preview';
import AttachmentIcon from '@mui/icons-material/Attachment';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { Table, Breadcrumb, Avatar, Drawer, Divider, Skeleton, Modal, Input  } from "antd";
import { ArrowRightOutlined, ArrowLeftOutlined } from "@ant-design/icons";
import { itemRender, onShowSizeChange } from "../../paginationfunction";
import { Link } from 'react-router-dom';
import { BaseImageUrl } from '../../../Components/BaseImageUrl';
import AddClientComp from '../../../AdminComponents/AddClientComp';
import UpdateClient from '../../../AdminComponents/UpdateClientComp';
import { Snackbar } from '@material-ui/core';
import { styled } from '@mui/material/styles';
import IconButton from '@mui/material/IconButton';
import { BaseUrl } from '../../../Components/BaseUrl';
import { baseUrl } from '../../Server/baseUrls';

const BootstrapDialog = styled(Dialog)(({ theme }) => ({
    '& .MuiDialogContent-root': {
      padding: theme.spacing(2),
    },
    '& .MuiDialogActions-root': {
      padding: theme.spacing(1),
    },
}));

function Viewings() {
    const [viewings, setViewings] = useState([]);
    const [loading, setLoading] = useState(false);
    const [name, setName] = useState("");
    const [id, setID] = useState("");
    const [showAdd, setShowAdd] = useState(false);
    const [showUpdate, setShowUpdate] = useState(false);
    const [errors, setErrors] = useState({});
    const [isAddClientModalOpen, setIsAddClientModalOpen] = useState(false);
    const [isUpdateClientModalOpen, setIsUpdateClientModalOpen] = useState(false);
    const [updateClientData, setUpdateClientData] = useState({});
    const [recordID, setRecordID] = useState("");
    const [open, setOpen] = useState(false);
    const [showToast, setShowToast] = useState(false);
    const [openDrawer, setOpenDrawer] = useState(false);
    const [client, setClient] = useState({});
    const [profileLoading, setProfileLoading] = useState(false);
    const [country, setCountry] = useState("");
    const [status, setStatus] = useState("");
    const [search, setSearch] = useState("");
    const [isLoading,setIsLoading] = useState(false)
    const token = useSelector((state) => state.login.token);
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
        },
    };

    const handleCloseToast = (event, reason) => {
        if (reason === 'clickaway') {
          return;
        }
        setShowToast(false);
    };

    const showAddClientModal = () => {
        setIsAddClientModalOpen(true);
    };
    const handleAddClientOk = () => {
        setIsAddClientModalOpen(false);
    };
    const handleAddClientCancel = () => {
        setIsAddClientModalOpen(false);
    };

    const showUpdateClientModal = (record) => {
        setIsUpdateClientModalOpen(true);
        setUpdateClientData(record);
        setRecordID(record.id);
    };
    const handleUpdateClientOk = () => {
        setIsUpdateClientModalOpen(false);
    };
    const handleUpdateClientCancel = () => {
        setIsUpdateClientModalOpen(false);
    };

    const showDrawer = (record_id) => {
        setProfileLoading(true);
        setClient({});
        setOpenDrawer(true);

        axios.get(baseUrl + "/api/owners_details/" + record_id, axiosConfig)
        .then((res) => {
            if(res.data.success) {
                setClient(res.data.client);
                setProfileLoading(false);
            }
        });
    };
    const onCloseDrawer = () => {
        setOpenDrawer(false);
    };

    useEffect(() => {
        const page = 1;
        const perPage = 10;
        fetchData(page, perPage);
    }, []);

    const handleShowAdd = () => {
        setName("");
        setShowAdd(true);
    };

    const handleCloseAdd = () => {
        setShowAdd(false);
    };

    const handleAddOk = () => {
        if(validateForm()) {
          setIsLoading(true)
            const data = { name: name };

            axios.post(baseUrl + "/api/developers", data, axiosConfig)
            .then((res) => {
                if(res.data.success) {
                  setIsLoading(false)
                    setName("");
                    setShowAdd(false);
                    const page = 1;
                    const perPage = 10;
                    fetchData(page, perPage);
                }

            }).catch((err) =>{
              setIsLoading(false)
              console.log("err",err);
          });
        }
    };

    const handleShowUpdate = (record) => {
        setName(record.label);
        setID(record.value);
        setShowUpdate(true);
    };

    const handleCloseUpdate = () => {
        setShowUpdate(false);
    };

    const handleUpdateOk = () => {
        if(validateForm()) {
          setIsLoading(true)

            const data = { id: id, name: name };

            axios.post(baseUrl + "/api/updatedeveloper", data, axiosConfig)
            .then((res) => {
                if(res.data.success) {
                  setIsLoading(false)
                    setName("");
                    setShowUpdate(false);
                    const page = 1;
                    const perPage = 10;
                    fetchData(page, perPage);
                }
            }).catch((err) =>{
              setIsLoading(false)
              console.log("err",err);
          });
        }
    };

    const refreshPage = () => {
        setIsAddClientModalOpen(false);
        setIsUpdateClientModalOpen(false);
        const page = 1;
        const perPage = 10;
        fetchData(page, perPage);
    };

    const searchClient = (e) => {
        const page = 1;
        const perPage = 10;
        const search = e.target.value;
        setSearch(e.target.value);
        fetchData(page, perPage);
    };

    const setStatusFilter = (e) => {
        const page = 1;
        const perPage = 10;
        const status = e.target.value;
        setStatus(e.target.value);
        fetchData(page, perPage);
    };

    const fetchData = async (page, perPage) => {
        try {
            setLoading(true);
            const url = `${baseUrl}/api/viewings?page=${page}&perPage=${perPage}`;
            const response = await axios.get(url, axiosConfig);
            setLoading(false);
            if(response.data.success)
            setViewings(response.data.viewings);
        } catch (error) {
          setLoading(false);
            console.error("Error:", error);
        }
    };
    
    const handleTableChange = (pagination) => {
        const page = pagination.current;
        const perPage = pagination.pageSize;
        fetchData(page, perPage);
    };

    const handleDelete = (record_id) => {
      
        setOpen(true);
        setRecordID(record_id);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleAgreeDelete = () => {
        axios.delete(baseUrl + "/api/developers/" + recordID, axiosConfig)
        .then((res) => {
            setOpen(false);
            if(res.data.success) {
                const page = 1;
                const perPage = 10;
                setTimeout(() => {
                    fetchData(page, perPage);
                    setShowToast(true);
                }, 200);
            }
        }).catch((err) =>{
          console.log("error",err);
        });
    };

    const validateForm = () => {
        const newErrors = {};
        if(!name) {
            newErrors.name = 'Name is required';
        }
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };

    const columns = [
        {
          title: "Date",
          dataIndex: "date",
        },
        {
            title: "Location",
            dataIndex: "location",
          },
          {
            title:"Status",
            render: (text, record) => (
                <select className='form-select' value={record.status}>
                    <option value="Pending">Pending</option>
                    <option value="Approved">Approved</option>
                    <option value="Rejected">Rejected</option>
                </select>
            )
          },
          {
            title: "Agent",
            render: (text, record) => (
                <>
                 {record.user?record.user.name:null}
                </>
            )
          },
        // {
        //   title: "Actions",
        //   render: (text, record) => (
        //     <div className="ms-auto">
        //       <Button
        //         variant="outlined"
        //         color="primary"
        //         sx={{
        //           marginBottom: '1rem',
        //           color: 'black',
        //           borderColor: 'black',
        //           '&:hover': {
        //             borderColor: 'black',
        //             backgroundColor: '#f5f5f5',
        //           },
        //         }}
        //         startIcon={<ModeEditOutlineIcon />}
        //         onClick={() => handleShowUpdate(record)}
        //       >
        //         Update
        //       </Button>
        //       <Button className='ml-2'
        //                     variant="outlined" color="error"
        //                     // startIcon={<AddIcon />}
        //                     // onClick={handleOpenModal}
        //                     style={{ marginBottom: '1rem', marginLeft: '1rem' }}
        //                     onClick={() => handleDelete(record.value)}
        //                     startIcon={<DeleteOutlineIcon />}
        //                 >
        //                     Delete
        //                 </Button>
        //     </div>
        //   ),
        // },
      ];

   

  return (
    <Container maxWidth={'xl'}>
    
      <Snackbar
        open={showToast}
        onClose={handleCloseToast}
        autoHideDuration={1500}
        anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      >
        <Alert severity="success" variant="filled">
          Developer Successfully deleted
        </Alert>
      </Snackbar>
      <BootstrapDialog
        onClose={handleClose}
        aria-labelledby="customized-dialog-title"
        open={open}
      >
        <DialogTitle sx={{ m: 0, p: 2 }} id="customized-dialog-title">
          Delete Developer
        </DialogTitle>
        <IconButton
          aria-label="close"
          onClick={handleClose}
          sx={{
            position: 'absolute',
            right: 8,
            top: 8,
            color: (theme) => theme.palette.grey[500],
          }}
        >
          <CloseIcon />
        </IconButton>
        <DialogContent dividers>
          <Typography gutterBottom>
            Are you sure to delete? You will not be able to revert this action.
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button variant="outlined" color="error" onClick={handleClose} startIcon={<CloseIcon />}>Close</Button>
          <Button variant="outlined" onClick={handleAgreeDelete} startIcon={<CheckIcon />}>Continue</Button>
        </DialogActions>
      </BootstrapDialog>
      <Modal width={400} title="Add New Developer" open={showAdd} onOk={handleAddOk} onCancel={handleCloseAdd} confirmLoading={isLoading}>
        <Divider />
        <label>Name</label>
        <Input value={name} onChange={(e) => setName(e.target.value)} />
        {errors.name && <Alert severity="error">{errors.name}</Alert>}
      </Modal>
      <Modal width={400} title="Update Developer" open={showUpdate} onOk={handleUpdateOk} onCancel={handleCloseUpdate} confirmLoading={isLoading}>
        <Divider />
        <label>Name</label>
        <Input value={name} onChange={(e) => setName(e.target.value)} />
        {errors.name && <Alert severity="error">{errors.name}</Alert>}
      </Modal>
      <div>
        <Grid container spacing={2} display={'flex'} alignItems={'center'} marginBottom={'1rem'}>
          <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
            <Typography variant='h3' align='left' width={'100%'} marginBottom={'1rem'}>
              Viewings Requests
            </Typography>
          </Grid>
    
        </Grid>
        <Grid item xs={12} sm={12} md={6} xl={6} lg={6}>
          <Table
            onChange={handleTableChange}
            loading={loading}
            className="table-striped"
            pagination={{
              pageSizeOptions: ["10", "20", "50", "100"],
              total: viewings?.total > 0 && viewings.total,
              pageSize: viewings.per_page,
              current: viewings.current_page,
              showTotal: (total, range) => `Showing ${range[0]} to ${range[1]} of ${total} entries`,
              showSizeChanger: true,
              onShowSizeChange: onShowSizeChange,
              itemRender: itemRender,
            }}
            style={{ overflowX: "auto" }}
            columns={columns}
            dataSource={viewings?.data ? viewings?.data : []}
          />
        </Grid>
      </div>
    </Container>
  );
}

export default Viewings;
