import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import { useState } from 'react';
// Local
import AppHeader from "../Components/Header";
import Footer from "../Components/Footer";
import "./index.css";
import Listings from "../Pages/Listings/listings";
import ListingsDetails from "../Pages/Listings/listingsDetails";
import Setting from "../Pages/Setting/setting";
import Home from "../Pages/Home/home";
import Account from "../Pages/Account/account";
import Listings_Listed_With_K_Estates from "../Pages/Listings/listing_listed_with_K_estates";
import { Container } from "@mui/material";
import HomePageComp from "../Components/HomeParticle";
import AdminDashboardArea from "../Pages/AdminPanel/dashboardArea";
import AdminHeader from "../AdminComponents/Header";
import Clients from "../Pages/AdminPanel/K-Estates-Clients/Clients";
import Properties from "../Pages/AdminPanel/K-Estates-Properties/Properties";
import NotFoundPageComp from "../Components/NotFoundPageComp"

import { Switch } from '@mui/material'
import { useSelector } from "react-redux";
import AdminPrivate from "../Components/protectRoutes/AdminPrivate";
import Login from "../Pages/Login/login";
import PublicRoute from "../Components/protectRoutes/PublicRoute";
import OwnerRoute from "../Components/protectRoutes/OwnerRoute";
import AllDashboard from "../Pages/Dashboard/AllDashboard";
import Owners from "../Pages/AdminPanel/K-Estates-Clients/Owners";
import AllProperties from "../Pages/AdminPanel/K-Estates-Properties/AllProperties";
import PaymentPlan from "../AdminComponents/PaymentPlan";
import ResetPassword from "../Pages/ResetPassword";
import Developer from "../Pages/AdminPanel/Settings/Developer";
import Locations from "../Pages/AdminPanel/Settings/Locations";
import PropertyTypes from "../Pages/AdminPanel/Settings/PropertyTypes";
import ForgotPassword from "../Pages/ForgotPassword";
import Contacts from "../Pages/AdminPanel/K-Estates-Clients/Contacts";
import Viewings from "../Pages/AdminPanel/AgentRequests/Viewings";
import Requests from "../Pages/AdminPanel/AgentRequests/Requests";
import Screen from "../Pages/SaleRentScreen/Screen";
import AdminScreen from "../Pages/AdminPanel/deals/AdminScreen";
import Launches from "../Pages/AdminPanel/Launch/Launches";
import ScreenHeader from "../Components/ScreenHeader";
import KycForm from "../Pages/AdminPanel/Settings/KycForm";
import Kyc from "../Pages/AdminPanel/K-Estates-Clients/Kyc";
import KypForm from "../Pages/AdminPanel/Settings/KypForm";
import Kyp from "../Pages/AdminPanel/K-Estates-Clients/Kyp";
import Materials from "../Pages/AdminPanel/AgentRequests/Materials";
import AgentToAgentAgreementForm from "../Pages/AdminPanel/Settings/AgentToAgentAgreementForm";
import Aspdfproject from "../Pages/AdminPanel/Settings/Aspdfproject";
import AgentToAgent from "../Pages/AdminPanel/K-Estates-Clients/AgentToAgent";
import AllMaterials from "../Pages/AdminPanel/AgentRequests/AllMaterials";
import DeletedAgents from "../Pages/AdminPanel/K-Estates-Clients/DeletedAgents";
import InviterMessage from "../Pages/AdminPanel/Settings/InviterMessage";
import Inviters from "../Pages/AdminPanel/Settings/Inviters";
import Rsvps from "../Pages/AdminPanel/Settings/Rsvps";


// const userRole = 'client';
// const userRole = 'admin';

const ProjectRoutes = ({ path, open }) => {
  const userInfo = useSelector((state) => state.login.user);

  const [switchState, setSwitchState] = useState(false);
  const [userRole, setUserRole] = useState('client');

  // Function to handle switch change
  const handleSwitchChange = () => {
      setSwitchState(!switchState);
      setUserRole( !switchState? 'admin' : 'client')
  };
  return (
    <Router>
      <div className="main-container">
      {userInfo ?<>{ userInfo.role_id == 1?<AdminHeader />:null}
      </>:null}
      
        <Routes>
          <Route
            exact
            path="/"
            element={
              <AdminPrivate requiredRole="3" >
                <AllDashboard />
              </AdminPrivate>
            }
          />

<Route
            path="/messages"
            element={
              <AdminPrivate requiredRole ="1">
                <InviterMessage />
              </AdminPrivate>
            }
          />
          <Route
            path="/inviters"
            element={
              <AdminPrivate requiredRole ="1">
                <Inviters />
              </AdminPrivate>
            }
          />

<Route
            path="/rsvps"
            element={
              <AdminPrivate requiredRole ="1">
                <Rsvps />
              </AdminPrivate>
            }
          />

<Route
            path="/kycs"
            element={
              <AdminPrivate requiredRole ="1">
                <Kyc />
              </AdminPrivate>
            }
          />
          <Route
            path="/kyps"
            element={
              <AdminPrivate requiredRole ="1">
                <Kyp />
              </AdminPrivate>
            }
          />

<Route
            path="/agentForms"
            element={
              <AdminPrivate requiredRole ="1">
                <AgentToAgent />
              </AdminPrivate>
            }
          />

          <Route
            path="/PropertyTypes"
            element={
              <AdminPrivate requiredRole ="1">
                <PropertyTypes />
              </AdminPrivate>
            }
          />

           <Route
            path="/Developers"
            element={
              <AdminPrivate requiredRole ="1">
                <Developer />
              </AdminPrivate>
            }
          />

          <Route
            path="/Locations"
            element={
              <AdminPrivate requiredRole ="1">
                <Locations />
              </AdminPrivate>
            }
          />
         
          <Route
            path="/Properties"
            element={
              <AdminPrivate requiredRole ="1">
                <AllProperties />
              </AdminPrivate>
            }
          />

          <Route
            path="/Agents"
            element={
              <AdminPrivate requiredRole ="1">
                <Owners />
              </AdminPrivate>
            }
          />

<Route
            path="/Deleted-Agents"
            element={
              <AdminPrivate requiredRole ="1">
                <DeletedAgents />
              </AdminPrivate>
            }
          />

<Route
            path="/Owners"
            element={
              <AdminPrivate requiredRole ="1">
                <Contacts />
              </AdminPrivate>
            }
          />

<Route
            path="/Launches"
            element={
              <AdminPrivate requiredRole ="1">
                <Launches  />
              </AdminPrivate>
            }
          />

<Route
            path="/Deals"
            element={
              <AdminPrivate requiredRole ="1">
                <AdminScreen />
              </AdminPrivate>
            }
          />

<Route
            path="/Viewings"
            element={
              <AdminPrivate requiredRole ="1">
                <Viewings />
              </AdminPrivate>
            }
          />

<Route
            path="/Requests"
            element={
              <AdminPrivate requiredRole ="1">
                <Requests />
              </AdminPrivate>
            }
          />

<Route
            path="/Materials"
            element={
              <AdminPrivate requiredRole ="1">
                <Materials />
              </AdminPrivate>
            }
          />

<Route
            path="/AllMaterials"
            element={
              <AdminPrivate requiredRole ="1">
                <AllMaterials />
              </AdminPrivate>
            }
          />

          <Route
            path="/Payments-Plan"
            element={
              <AdminPrivate requiredRole ="1">
                <PaymentPlan />
              </AdminPrivate>
            }
          />

          {/* owners routes */}

          <Route
            path="/Listings"
            element={
              <AdminPrivate requiredRole ="2">
                <Listings />
              </AdminPrivate>
            }
          />

          <Route
            path="/Listings_Listed_With_K_Estaes"
            element={
              <AdminPrivate requiredRole ="2">
                <Listings_Listed_With_K_Estates />
              </AdminPrivate>
            }
          />
          
          <Route
            path="/ListingsDetails"
            element={
              <AdminPrivate requiredRole ="2">
                <ListingsDetails />
              </AdminPrivate>
            }
          />

          <Route
            path="/Setting"
            element={
              <AdminPrivate requiredRole ="2">
                <Setting />
              </AdminPrivate>
            }
          />
          <Route
            path="/Account"
            element={
              <AdminPrivate requiredRole ="2">
                <Account />
              </AdminPrivate>
            }
          />

          <Route
            path="/Reset_Password"
            element={
              <AdminPrivate requiredRole ="3">
                <ResetPassword />
              </AdminPrivate>
            }
          />

          <Route
            path="/Forgot_Password"
            element={
              <PublicRoute>
                <ForgotPassword />
              </PublicRoute>
            }
          />

          <Route
            path="/login"
            element={
              <PublicRoute>
                <Login />
              </PublicRoute>
            }
          />

<Route
            path="/kyc"
            element={
              <PublicRoute>
                <KycForm />
              </PublicRoute>
            }
          />

<Route
            path="/agent-to-agent"
            element={
              <PublicRoute>
                <AgentToAgentAgreementForm />
              </PublicRoute>
            }
          />

<Route
            path="/aspdf"
            element={
              <PublicRoute>
                <Aspdfproject />
              </PublicRoute>
            }
          />



<Route
            path="/kyp"
            element={
              <PublicRoute>
                <KypForm />
              </PublicRoute>
            }
          />

          <Route
            path="/dashboard"
            element={
              <PublicRoute>
                <Screen />
              </PublicRoute>
            }
          />


{/* <Route exact path="*" element={<NotFoundPageComp />} /> */}
      
        </Routes>
      </div>
      {userInfo?<Footer />:null}
      
    </Router>
  );
};

export default ProjectRoutes;
