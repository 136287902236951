import React from 'react'
import "./Aspdf.css"
export default function Aspdfproject() {
  return (
    <div>
    <div>
  <h1>Agent Property Details</h1>
  {/* Agent A Details */}
  <div className="section">
    <div className="section-title">Agent A Details</div>
    <div className="section-content">
      <div className="data"><span className="label">Establishment Name:</span> K Estates LLC</div>
      <div className="data"><span className="label">Address:</span> Office 402 Park Heights Square 1, Dubai Hills Estate</div>
      <div className="data"><span className="label">Phone:</span> 04 529 9724</div>
      <div className="data"><span className="label">Email:</span> info@kestates.ae</div>
      <div className="data"><span className="label">ORN:</span> 30498</div>
      <div className="data"><span className="label">DED License:</span> 1067808</div>
      <div className="data"><span className="label">PO Box:</span> 12205</div>
      <div className="data"><span className="label">BRN:</span> </div>
      <div className="data"><span className="label">Date Issued:</span> </div>
      <div className="data"><span className="label">Mobile:</span> </div>
      <div className="data"><span className="label">Email (Form):</span> </div>
      <div className="data"><span className="label">Seller Form A STR:</span> </div>
    </div>
  </div>
  {/* Agent B Details */}
  <div className="section">
    <div className="section-title">Agent B Details</div>
    <div className="section-content">
      <div className="data"><span className="label">Establishment Name:</span> </div>
      <div className="data"><span className="label">Address:</span> </div>
      <div className="data"><span className="label">Phone:</span> </div>
      <div className="data"><span className="label">Email:</span> </div>
      <div className="data"><span className="label">ORN:</span> </div>
      <div className="data"><span className="label">DED License:</span> </div>
      <div className="data"><span className="label">PO Box:</span> </div>
      <div className="data"><span className="label">BRN:</span> </div>
      <div className="data"><span className="label">Date Issued:</span> </div>
      <div className="data"><span className="label">Mobile:</span> </div>
      <div className="data"><span className="label">Email (Form):</span> </div>
      <div className="data"><span className="label">Seller Form A STR:</span> </div>
    </div>
  </div>
  {/* Property Details */}
  <div className="section">
    <div className="section-title">Property Details</div>
    <div className="section-content">
      <div className="data"><span className="label">Property Address:</span> </div>
      <div className="data"><span className="label">Master Developer:</span> </div>
      <div className="data"><span className="label">Master Project Name:</span> </div>
      <div className="data"><span className="label">Description:</span> </div>
      <div className="data"><span className="label">Listed Price:</span> </div>
      <div className="data"><span className="label">Maintenance Fee PA:</span> </div>
      <div className="data"><span className="label">Sq Ft:</span> </div>
      <div className="data"><span className="label">Commission Agent A:</span> </div>
      <div className="data"><span className="label">Commission Agent B:</span> </div>
      <div className="data"><span className="label">Client Name:</span> </div>
      <div className="data"><span className="label">Family Name:</span> </div>
      <div className="data"><span className="label">Transfer Fee Agent A:</span> </div>
      <div className="data"><span className="label">Transfer Fee Agent B:</span> </div>
      <div className="data"><span className="label">Budget:</span> </div>
      <div className="data"><span className="label">Has Pre-Finance Approval:</span> </div>
      <div className="data"><span className="label">Has Contacted Listing Agent:</span> </div>
    </div>
  </div>
  {/* Signatures */}
  <div className="section">
    <div className="section-title">Signatures</div>
    <div className="section-content">
      <div className="data"><span className="label">Signature A:</span> </div>
      <div className="data"><span className="label">Signature B:</span> </div>
    </div>
  </div>
</div>

    </div>
  )
}
