import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    token: "",
    user: null,
    isLoggedIn:false
}

const loginSlice = createSlice({
    name: 'login',
    initialState,
    reducers: {
        loginSuccess: (state, action) => {
            // console.log("*** Action *** ", action)
            state.user = action.payload.user;
            state.isLoggedIn = true
        },  
        setToken: (state, action) => {
            state.token = action.payload.token;
        },
        logOut: (state) => {
            state.token = "";
            state.user = null;
            state.isLoggedIn = false
        }
    }
});

export const { loginSuccess, setToken, logOut } = loginSlice.actions;
export default loginSlice.reducer;
