import React, { useEffect, useState } from 'react';
import { 
    Button,
    Input,
    Typography,
    Avatar,
    Grid,
    TextField,
    RadioGroup,
    Radio,
    FormControlLabel,
    MenuItem,
    Select,
    InputLabel,
    FormControl
} from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import EditIcon from '@mui/icons-material/Edit';
// Local
import "./AddClientComp.css";
import { user } from '../LocalDB';
import axios from 'axios';
import { useSelector } from 'react-redux';
import { BaseUrl } from '../Components/BaseUrl';
import { baseUrl } from '../Pages/Server/baseUrls';
import CurrencyInput from 'react-currency-input-field';
import {DatePicker} from 'antd'
import { MultiSelect } from "react-multi-select-component";

const AddDealComp = ({xCLoseModal,agents}) => {
   
    const [name, setName] = useState('');
    const [date, setDate] = useState(null);
    const [value, setValue] = useState('');
    const [area,setArea] = useState('')
    const [type, setType] = useState('');
    const [user_id,setUserId] = useState('')
    const [selectedUsers, setSelectedUsers] = useState([]);


    // const [countries,setCountries] = useState([])
    const [inputs, setInputs] = useState({});
    const [errors, setErrors] = useState({});

    const token = useSelector((state) => state.login.token);

    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
            'Content-Type': 'multipart/form-data'
        },
    };


  
    const handleNameChange = (event) => {
      setName(event.target.value);
    };

 
    const handleAutocompleteChange = (event, value) => {
        const ids = value.map(option => option.id);
        setSelectedUsers(ids);
    }




    const validate = () => {
        let input = inputs;
        let errors = {};
        let isValid = true;
        if (!name) {
          isValid = false;
          errors["name"] = "Project is required";
        }

        if (!date) {
            isValid = false;
            errors["date"] = "Deal date is required";
          } 

          if (!value) {
            isValid = false;
            errors["value"] = "Deal value is required";
          }

          if (selectedUsers.length < 1) {
            isValid = false;
            errors["user_id"] = "Agents is required";
          }


        
       

        setErrors(errors)
        return isValid;
      };
  
    const handleAddDeal = () => {

   console.log(date);
    if(validate()){
      const formData = new FormData();
      formData.append('project', name);
      formData.append('dealValue', value);
      formData.append('saleDate', date);
      formData.append('location', area);
      formData.append('type', type);
     

      selectedUsers.forEach((file, index) => {
        formData.append(`agents[${index}]`, file.value);
    });

      axios.post(baseUrl+"/api/deals", formData, {
          headers: {
              'Content-Type': 'multipart/form-data', // Important: set content type to multipart/form-data
              ...axiosConfig.headers // Include any other headers
          }
      })
      .then((res) => {
        xCLoseModal()
          console.log("res server", res.data);
      })
      .catch((error) => {
        xCLoseModal()
          console.error("Error:", error);
      });
    
    }else{
        return
    }
}


  
    return(
        <div style={{ width:"100%", display:'flex', alignItems:"center" , justifyContent:"center"}} >
            <div className='addClient-container' >
                {/* <h1> User Acount </h1> */}
                {/* <Typography variant="h5" style={{ fontFamily:'Montserrat'}} >{user.name}</Typography>
                <Typography variant="caption" style={{ fontFamily:'Montserrat'}} >{user.address}</Typography> */}

            
                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Project"
                            value={name}
                            onChange={handleNameChange}
                        />
                         <div className="text-danger">
                          {errors.name}
                        </div>
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            fullWidth
                            label="Area"
                            value={area}
                            onChange={(e) =>setArea(e.target.value)}
                        />
                       
                    </Grid>

               
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={6} xl={6}>
                    <CurrencyInput
                            style={{
                                width: '100%', padding: '15px', boxSizing: 'border-box', border: '1px solid #ccc', borderRadius: '4px',
                                fontSize: '16px', transition: 'border-color 0.3s'
                            }}
                            id="input-example"
                            name="input-name"
                            label="Value"
                            placeholder="Value"
                            //   defaultValue={0}
                            value={value}
                            decimalsLimit={2}
                            onValueChange={(value, name, values) => setValue(value)}
                            error={!!errors.value}
                            helperText={errors.value}
                        />
                           <div className="text-danger">
                          {errors.value}
                        </div>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                            <TextField
                                fullWidth
                                label="Date"
                                 type="date"
                                value={date}
                                onChange={(e) =>setDate(e.target.value)}
                            />
                              <div className="text-danger">
                          {errors.date}
                        </div>
                    </Grid>

            
                </Grid> 


                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                    <InputLabel id="demo-simple-select-label">Type</InputLabel>
                            <Select
                                labelId="demo-simple-select-label"
                                fullWidth
                                label="Type"
                                value={type}
                                onChange={(e) =>setType(e.target.value)}
                            >
                            <MenuItem  value="Sale">Sale</MenuItem>
                            <MenuItem  value="Rent">Rent</MenuItem>

                                </Select>
                  
                    </Grid>

                    <Grid item xs={12} md={12} lg={6} xl={6} >
                       
                            <InputLabel id="demo-simple-select-label">Select Agent</InputLabel>
                            <MultiSelect
                            options={agents}
                            value={selectedUsers}
                            onChange={setSelectedUsers}
                            labelledBy="Select users"
                            overrideStrings={{
                                "selectSomeItems": "Select Users", // Custom placeholder text
                                allItemsAreSelected: "All Users Selected", // Text when all items are selected

                            }}
                        />
                            {/* <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={agents}
                                onChange={handleAutocompleteChange}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox


                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />

                                        {option.name}


                                    </li>
                                )}
                                style={{ width: "100%" }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Users" placeholder="" />
                                )}
                            /> */}
                            {/* <Select
                                labelId="demo-simple-select-label"
                                fullWidth
                                label="Select Country"
                                value={user_id}
                                onChange={(e)=>setUserId(e.target.value)}
                            >
                                {agents.map((agent,index)=>(
                                    <MenuItem key={index} value={agent.id}>{agent.name}</MenuItem>
                                ))}
                                
                            </Select> */}
                       
                        <div className="text-danger">
                          {errors.user_id}
                        </div>
                    </Grid>

                </Grid>
     

                <Grid item xs={12} style={{ marginTop: '2rem' }}>
                    <Button
                        variant="contained"
                        color="primary"
                        onClick={handleAddDeal}
                    >
                        Add Deal
                    </Button>
                </Grid>
            </div>
        </div>
    )
}

export default AddDealComp;