import React from 'react'
import {Outlet, Navigate } from 'react-router-dom'
import { useSelector } from 'react-redux';


const AdminPrivate = ({  children,requiredRole }) => {
  const token = useSelector((state) => state.login.token);
  const isLoggedIn = useSelector((state) => state.login.isLoggedIn);
  const user = useSelector((state) => state.login.user);
  // console.log(user,"user");
  const allusers = 3
  if(isLoggedIn){
    // console.log(requiredRole,"req");
    if(user?.role_id == requiredRole){
      return  children ;
    } else
    if(allusers == requiredRole){
      return  children ;
      return <Navigate to="/" replace />
    }else
    {

    return <Navigate to="/" replace />
    }
  }
  else{
   return <Navigate to="/login" replace />
  }



   

  

};


//  const AdminPrivate = ({ component: Component, ...rest }) => {
//   const token = useSelector((state) => state.login.token);
//   // const  token  = useSelector(state => state.login)
//   console.log(token);
  
 
 
//   let auth = {'token': false}
//   return(
//       token !="" ? <Outlet/> : <Navigate to="/"/>
  
//   )
// }


  export default AdminPrivate