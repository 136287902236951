import React, { useEffect, useRef, useState } from 'react';
import {
    Grid,
    Button,
    TextField,
    FormControl,
    FormLabel,
    RadioGroup,
    FormControlLabel,
    Radio,
    Select,
    MenuItem,
    InputLabel,
    Typography,
    Container,
    Avatar,
    FormHelperText
} from '@mui/material';

// import Select from 'react-select';
import $ from "jquery";
import ImageCompressor from 'image-compressor.js';
import Helmet from "react-helmet";
import Checkbox from '@mui/material/Checkbox';
import Autocomplete from '@mui/material/Autocomplete';
import CheckBoxOutlineBlankIcon from '@mui/icons-material/CheckBoxOutlineBlank';
import CheckBoxIcon from '@mui/icons-material/CheckBox';
import './AddPropertyComp.css';
import ImageUploading from 'react-images-uploading';
import ChangeCircleIcon from '@mui/icons-material/ChangeCircle';
import RemoveCircleIcon from '@mui/icons-material/RemoveCircle';
import { useLocation } from 'react-router-dom';
import { AmountSaperator } from '../Utilities';
import axios from 'axios';
import { BaseUrl } from '../Components/BaseUrl';
import { useSelector } from 'react-redux';
import { BaseImageUrl } from '../Components/BaseImageUrl';
import { baseUrl, iconsUrl, profileImageBaseUrl } from '../Pages/Server/baseUrls';
import { MapContainer, TileLayer, Marker, Popup,useMap  } from 'react-leaflet';
import 'leaflet/dist/leaflet.css';
import L from 'leaflet'
import CurrencyInput from 'react-currency-input-field';
import BeatLoader from "react-spinners/BeatLoader";
import SaveIcon from '@mui/icons-material/Save';
import { Divider, Modal  } from 'antd';


const AddPropertyComp = ({ owner, locations, developers, property_types,amenities = [],contacts, xCloseModal }) => {

 
  

    const token = useSelector((state) => state.login.token);
    const axiosConfig = {
        headers: {
            Authorization: `Bearer ${token}`, // Include the token as a Bearer token
        },
    };
    let map;
    const { state } = useLocation();
    const [propertyImages, setPropertyImages] = useState([]);
    const [propertyName, setPropertyName] = useState('');
    const [bedrooms, setBedrooms] = useState('');
    const [bathrooms, setBathrooms] = useState('');
    const [totalArea, setTotalArea] = useState('');
    const [location, setLocation] = useState('');
    const [developer, setDeveloper] = useState('')
    const [listingType, setListingType] = useState('');
    const [propertyType, setPropertyType] = useState('');
    const [expectedPrice, setExpectedPrice] = useState('');
    const [showimages, setShowImages] = useState([]);
    const [images, setImages] = useState([]);
    const [selectedUsers, setSelectedUsers] = useState([]);
    const [selectedData, setSelectdData] = useState([])
    const [owners, setOwners] = useState([])
    const [errors, setErrors] = useState({});
    const [loading, setLoading] = useState(false)
    const [unitno, setUnitno] = useState('');
    const [ lat,setLat] = useState(25.1972018)
    const [ lng,setLng] = useState(55.2721877)
    const [coordinates, setCoordinates] = useState([25.1972, 55.2721877]);
    const [locationName, setLocationName] = useState('');
    const selectRef = useRef(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isDocumentsModalOpen,setIsDocumentsModalOpen] = useState(false)
    const [selectedAmenities, setSelectedAmenities] = useState([]);
    const [floorplan,setFloorplan] = useState(null)
    const [masterplan,setMasterplan] = useState(null)
    const [paymentplan,setPaymentplan] = useState(null)
    const [brochure,setBrochure] = useState(null)
    const [video,setVideo] = useState(null)
    const [factSheet,setFactSheet] = useState(null)
    const [renders,setRenders] = useState(null)
    const [contact, setContact] = useState('')
    const [url, setUrl] = useState('')
    const [description, setDescription] = useState('')
    const [furnishType, setFurnishType] = useState('');
    const [isListed, setIsListed] = useState(false);

    
    useEffect(() => {
        if (window.google && window.google.maps) {
            initMap();
          } else {
            window.initMap = initMap;
          }
        
       
     },[lat,lng])

    const showModal = () => {
        setIsModalOpen(true);
      };
      const handleOk = () => {
        setIsModalOpen(false);
      };
      const handleCancel = () => {
        setIsModalOpen(false);
      };
      const showDocumentModal = () =>{
        setIsDocumentsModalOpen(true);
      }

      const handleDocumentsOk = () => {
        setIsDocumentsModalOpen(false);
      };
      const handleDocumentsCancel = () => {
        setIsDocumentsModalOpen(false);
      };
    const handleUserChange = (event) => {
        setSelectedUsers(event.target.value);
    };

  const mapDefaultZoom =10
    const ZoomOutOnSelect = () => {
        const map = useMap();
        map.setView(coordinates, mapDefaultZoom); // Set the view to the selected location's coordinates with the default zoom level
        return null;
    };

    const customMarkerIcon = new L.Icon({
        iconUrl: 'https://leafletjs.com/examples/custom-icons/leaf-red.png',
        iconSize: [25, 41], // size of the icon
        iconAnchor: [12, 41], // point of the icon which will correspond to marker's location
        popupAnchor: [0, -41], // point from which the popup should open relative to the iconAnchor
    });
    const compressImages = async () => {
        const compressedImages = [];

        for (let i = 0; i < images.length; i++) {
            const file = images[i];
            const options = {
                maxWidth: 800,
                maxHeight: 600,
                quality: 0.7, // Adjust compression quality as needed (0.7 means 70%)
                success(result) {
                    compressedImages.push(result);
                },
                error(e) {
                    console.error(e.message);
                },
            };

            await new ImageCompressor(file, options).compress();
        }
    }

    const maxNumber = 69;

    const onChange = (imageList, addUpdateIndex) => {
        const newimages = imageList.map(option => option.file);
        setShowImages(imageList);
        setImages(newimages)
    };




    const handleImageUpload = (event) => {
        const files = Array.from(event.target.files);
        setPropertyImages(files);
    };

    const handleFormSubmit = () => {
        // Implement form submission logic here
        //   console.log('Form submitted:', {
        //     propertyImages,
        //     propertyName,
        //     bedrooms,
        //     bathrooms,
        //     totalArea,
        //     location,
        //     listingType,
        //     propertyType,
        //     expectedPrice,
        //   });
    };



    const handleAutocompleteChange = (event, value) => {
        const ids = value.map(option => option.id);
        setSelectedUsers(ids);
    }

    const validateForm = () => {
        const newErrors = {};
        if (!propertyName) newErrors.propertyName = 'Property name is required';
        if (!bedrooms) newErrors.bedrooms = 'Number of bedrooms is required';
        if (!bathrooms) newErrors.bathrooms = 'Number of bathrooms is required';
        if (!totalArea) newErrors.totalArea = 'Total area is required';
        if (!location) newErrors.location = 'Location is required';
        if (!developer) newErrors.developer = 'Developer is required';
        if (!expectedPrice) newErrors.expectedPrice = 'Expected price is required';
        if (!propertyType) newErrors.propertyType = 'Property type is required';
        if (!listingType) newErrors.listingType = 'Listing type is required';
        if (!unitno) newErrors.unitno = 'Unitno is required';
        setErrors(newErrors);
        return Object.keys(newErrors).length === 0;
    };
    const handleSubmit = async () => {
        
       
       

        if (!validateForm()) return;
        setLoading(true)
        const formData = new FormData();
        // images.forEach((file, index) => {
        //     formData.append(`images[${index}]`, file);
        // });

        selectedUsers.forEach((file, index) => {
            formData.append(`owners[${index}]`, file);
        });

      

        selectedAmenities.forEach((file, index) => {
            formData.append(`amenities[${index}]`, file);
        });


        formData.append("title", propertyName)
        formData.append("bedrooms", bedrooms)
        formData.append("bathrooms", bathrooms)
        formData.append("area_size", totalArea)
        formData.append("location_id", location)
        formData.append("property_type_id", propertyType)
        formData.append("status", listingType)
        formData.append("price", expectedPrice)
        formData.append("developer_id", developer)
        formData.append("unit_no", unitno)
        formData.append("lat", lat)
        formData.append("lng", lng)
     
        formData.append("contact_id", contact)
        formData.append("url", url)
        formData.append("description", description)
        formData.append("furnished", furnishType)
        formData.append("isListed", isListed?"Listed":"Pocket")
        
        const fileTypes = [];

        if (brochure) {
            formData.append('files[]', brochure);
            fileTypes.push('brochure');
          }
          if (factSheet) {
            formData.append('files[]', factSheet);
            fileTypes.push('factSheet');
          }
          if (paymentplan) {
            formData.append('files[]', paymentplan);
            fileTypes.push('paymentplan');
          }
        if (floorplan) {
          formData.append('files[]', floorplan);
          fileTypes.push('floorplan');
        }
       
        if (renders) {
          formData.append('files[]', renders);
          fileTypes.push('renders');
        }
        if (video) {
          formData.append('files[]', video);
          fileTypes.push('video');
        }
        
        formData.append('file_types', JSON.stringify(fileTypes));


        try {
            const response = await axios.post(baseUrl + "/api/properties", formData, axiosConfig);
            if (response.data.success) {
                setLoading(false);
                setUnitno("")
                setBathrooms("")
                setBedrooms("")
                setDeveloper("")
                setExpectedPrice("")
                setListingType("")
                setLocation("")
                setPropertyName("")
                setSelectedUsers([])
                setTotalArea("")
                setPropertyType("")
                setLat(25.1972018)
                setLng(55.2721877)
                setFloorplan(null)
                setMasterplan(null)
                setPaymentplan(null)
                setBrochure(null)
                 initMap()
              
            
                let hasFiles = false;
                const imagesFormData = new FormData();
                images.forEach((file, index) => {
                    if(file && file != undefined){
                        imagesFormData.append(`images[${index}]`, file);
                        hasFiles = true;
                    }
                });

               
                imagesFormData.append("property_id", response.data.property_id);
                if (hasFiles) {
                axios.post(baseUrl + "/api/upload-files", imagesFormData, axiosConfig)
                .then((res) =>{
                    setImages([])
                    setShowImages([])
                    xCloseModal()
                    console.log("images upload",res.data);
                }).catch((err) =>{
                    console.log("err:",err);
                })
            }else{
                console.log("no images");

            }
            
            xCloseModal();
            } else {
                // Handle response with success: false
                setLoading(false);
                console.log("Server response was not successful:", response.data);
            }
        } catch (error) {
            setLoading(false);
            console.error("Error during form submission:", error);
        }
        // axios.post(baseUrl + "/api/properties", formData, axiosConfig)
        //     .then((res) => {
        //         if (res.data.success) {
        //             setLoading(false)
        //             xCloseModal()
        //             setTimeout(() => {
        //                 initialValues();
        //             }, 1000);
        //         }
        //     })
        //     .catch((err) => {
        //         setLoading(false)
        //         console.log("errorr", err);
        //     })
    }

    const initialValues = () => {
        setImages([]);
        setPropertyName("");
        setBedrooms("");
        setTotalArea("");
        setLocation("");
        setPropertyType(1);
        setListingType("Rent");
        setExpectedPrice("");
        setDeveloper("");
        setSelectedUsers([]);
        setBathrooms("")
    }

const handleLocation = (e) =>{
    setLocation(e.target.value)
    const selectedLocation = locations.find(loc => loc.value === e.target.value);
    $("#location_selected").val(selectedLocation.label);
    // if (selectedLocation) {
    //     setLocationName(selectedLocation.label);
    //     // Fetch coordinates for the selected location
    //     try {
    //         const response = await axios.get(`https://nominatim.openstreetmap.org/search`, {
    //             params: {
    //                 q: selectedLocation.label,
    //                 format: 'json',
    //                 // limit: 1
    //             }
    //         });
    //         if (response.data.length > 0) {
    //             const { lat, lon } = response.data[0];
    //             setCoordinates([parseFloat(lat), parseFloat(lon)]);
    //         } else {
    //             console.error('No coordinates found for the location.');
    //         }
    //     } catch (error) {
    //         console.error('Error fetching coordinates:', error);
    //     }
    // }
  
    // setCoordinates([selectedLocation.lat, selectedLocation.lng]);
   
    initMap()
}

const handleCheckboxChange = (event, amenityId) => {
    if (event.target.checked) {
        setSelectedAmenities(prevSelectedAmenities => [...prevSelectedAmenities, amenityId]);
    } else {
        setSelectedAmenities(prevSelectedAmenities => prevSelectedAmenities.filter(id => id !== amenityId));
    }
};

const renderMap = () => {
    window.initMap = initMap;
  };
  const initMap = () => {
    let service;
    let infowindow;
    const loc = $("#location_selected").val()
   
    const request = {
      query: loc?loc:"Burj khalifa",
      fields: ["name", "geometry"],
    };

    infowindow = new window.google.maps.InfoWindow();
    map = new window.google.maps.Map(document.getElementById("google-map"), {
      center: new window.google.maps.LatLng(lat, lng),
      zoom: 15,
      mapTypeId: window.google.maps.MapTypeId.HYBRID,
      zoomControl: true,
      mapTypeControl: true,
      scaleControl: false,
      streetViewControl: false,
      rotateControl: false,
      fullscreenControl: false,
      scrollwheel: false,
      draggable: true,
      gestureHandling: "cooperative",
      // styles: MAP_STYLES
    });

    service = new window.google.maps.places.PlacesService(map);
    
    service.findPlaceFromQuery(request, (results, status) => {

        
      if (
        status === window.google.maps.places.PlacesServiceStatus.OK &&
        results
      ) {
        for (let i = 0; i < results.length; i++) {
          createMarker(results[0]);
        }

        map.setCenter(results[0].geometry.location);
        setLat(results[0].geometry.location.lat())
        setLng(results[0].geometry.location.lng())
        $("#lat").val(results[0].geometry.location.lat());
        $("#lng").val(results[0].geometry.location.lng());
      }
    });
  };
  const createMarker =(place) => {
    if (!place.geometry || !place.geometry.location) return;

    const marker = new window.google.maps.Marker({
      map,
      position: place.geometry.location,
      draggable: true,
    });

    window.google.maps.event.addListener(marker, "dragend", function (event) {
      $("#lat").val(this.getPosition().lat());
      $("#lng").val(this.getPosition().lng());
      setLat(this.getPosition().lat())
      setLng(this.getPosition().lng())
    });

    map.addListener("click", (e) => {
      $("#lat").val(e.latLng.lat());
      $("#lng").val(e.latLng.lng());
      setLat(e.latLng.lat())
      setLng(e.latLng.lng())
      map.setCenter(marker.setPosition(e.latLng));
    });
  }

  const handleFloorplan = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.floorplan = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setFloorplan(null)
        return 
    }else{
        setFloorplan(file)
    }
 }

 const handleVideo = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.video = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setVideo(null)
        return 
    }else{
        setVideo(file)
    }
 }

 const handleFactSheet = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.factSheet = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setFactSheet(null)
        return 
    }else{
        setFactSheet(file)
    }
 }

 const handleRenders = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.renders = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setRenders(null)
        return 
    }else{
        setRenders(file)
    }
 }

 const handleMasterplan = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.masterplan = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setMasterplan(null)
        return 
    }else{
        setMasterplan(file)
    }
 }

 const handlePaymentplan = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.paymentplan = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setPaymentplan(null)
        return 
    }else{
        setPaymentplan(file)
    }
 }
 const handleBrochure = (e) =>{
    const file = e.target.files[0];
    const allowedTypes = ['application/pdf', 'image/png', 'image/jpeg', 'image/jpg'];
    if (file && !allowedTypes.includes(file.type)){
        const newErrors = {};
        newErrors.brochure = "Invalid file type. Please select a PDF, PNG, JPEG, or JPG file.";
        setErrors(newErrors);
        e.target.value = null;
        setBrochure(null)
        return 
    }else{
        setBrochure(file)
    }
 }

 const handleSelectAllChange = (event) => {
    if (event.target.checked) {
      const allAmenityIds = amenities.map(amn => amn.id);
      setSelectedAmenities(allAmenityIds);
    } else {
      setSelectedAmenities([]);
    }
  };

  const areAllSelected = amenities.length > 0 && selectedAmenities.length === amenities.length;


    return (
        // <Container maxWidth={'xl'} >

        
        <div  >
                  <Modal width={1000} title="Documents" footer={null} open={isDocumentsModalOpen} onOk={handleDocumentsOk} onCancel={handleDocumentsCancel}>
       <Divider />
       <table class="table table-bordered">
 
  <tbody>
    <tr>
      <th>Brochure</th>
      <td> <input
            type="file"
            required
            onChange={handleBrochure}
            name="number_of_installment"
            className="form-control"
          />
          </td>
    </tr>
    <tr>
      <th>Fact Sheet</th>
      <td><input
            type="file"
            required
            onChange={handleFactSheet}
            name="number_of_installment"
            className="form-control"
          /></td>
    </tr>
    <tr>
      <th>Payment Plan</th>
      <td><input
            type="file"
            required
            onChange={handlePaymentplan}
            name="number_of_installment"
            className="form-control"
          /></td>
    </tr>
    <tr>
      <th>Floor Plans</th>
      <td><input
            type="file"
            required
            onChange={handleFloorplan}
            name="number_of_installment"
            className="form-control"
          /></td>
    </tr>
    <tr>
      <th>Renders</th>
      <td><input
            type="file"
            required
            onChange={handleRenders}
            name="number_of_installment"
            className="form-control"
          /></td>
    </tr>

    <tr>
      <th>Videos</th>
      <td><input
            type="file"
            required
            onChange={handleVideo}
            name="number_of_installment"
            className="form-control"
          /></td>
    </tr>
 
  </tbody>
</table>
      </Modal>
             <Modal width={800} title="Amenities" footer={null} open={isModalOpen} onOk={handleOk} onCancel={handleCancel}>
       <Divider />
       <FormControlLabel
        control={
          <Checkbox
            checked={areAllSelected}
            onChange={handleSelectAllChange}
            color="primary"
          />
        }
        label="Select All"
      />
       <div className='row'>
        {Array.isArray(amenities) && amenities.map((amn,index)=>(
            <FormControlLabel
            key={index}
             className='col-md-3'
            label={
                <div style={{ display: 'flex', alignItems: 'center' }}>
                    <img src={iconsUrl+"/"+amn.icon } style={{height:'30px',width:'30px'}}  />
                    {/* {amn.icon} */}
                    <span style={{ marginLeft: 8 }}>{amn.name}</span>
                </div>
            }
            control={<Checkbox    checked={selectedAmenities.includes(amn.id)}
            onChange={(e) => handleCheckboxChange(e, amn.id)} />}
           
        />
        ))}
        </div>
      </Modal>
            <div className='' >
                <div className="">
                    <ImageUploading
                        multiple
                        value={showimages}
                        onChange={onChange}
                        maxNumber={maxNumber}
                        dataURLKey="data_url"
                    >
                        {({
                            imageList,
                            onImageUpload,
                            onImageRemoveAll,
                            onImageUpdate,
                            onImageRemove,
                            isDragging,
                            dragProps,
                        }) => (
                            // write your building UI
                            <div className="upload__image-wrapper" >
                                <Button variant="contained" type="submit" size='small' onClick={onImageUpload} style={{ marginLeft: '0rem', backgroundColor: "black", }} >
                                    Click or Drop here
                                </Button>
                                <Button variant="contained" type="submit" size='small' onClick={onImageRemoveAll} style={{ marginLeft: '1rem', backgroundColor: "black", }} >
                                    Remove all images
                                </Button>


                                <Grid container spacing={1} style={{ marginTop: '1rem', width: '100%' }} className='images-list' >
                                    {
                                        imageList.slice(0, 10).map((image, index) => (
                                            <Grid key={index} item xs={6} sm={6} md={4} lg={4} xl={3.5} margin={1} >
                                                <div className='image-controller' >
                                                    <ChangeCircleIcon onClick={() => onImageUpdate(index)} color='info' />
                                                    <RemoveCircleIcon onClick={() => onImageRemove(index)} color='error' />
                                                </div>
                                                <img src={image['data_url']} alt="" style={{ width: '100%', height: '100%' }} />
                                            </Grid>
                                        ))}
                                </Grid>
                                <Typography variant='caption' color={'lightgray'} >
                                    Only max 10 images can be shown
                                </Typography>
                            </div>
                        )}
                    </ImageUploading>
                </div>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={3} xl={3}>
                        <TextField
                            label="Unit no"
                            value={unitno}
                            onChange={(e) => setUnitno(e.target.value)}
                            fullWidth
                            error={!!errors.unitno}
                            helperText={errors.unitno}
                        />
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <TextField
                            label="Project"
                            value={propertyName}
                            onChange={(e) => setPropertyName(e.target.value)}
                            fullWidth
                            error={!!errors.propertyName}
                            helperText={errors.propertyName}
                        />
                    </Grid>

                    <Grid item xs={12} md={12} lg={3} xl={3}>
                        <TextField
                            label="Total Area"
                            value={totalArea}
                            onChange={(e) => setTotalArea(e.target.value)}
                            fullWidth
                            error={!!errors.totalArea}
                            helperText={errors.totalArea}
                        />
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                <Grid item xs={12} md={12} lg={5} xl={5}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Furnished status</FormLabel>
                            <RadioGroup
                                row
                                value={furnishType}
                                onChange={(e) => setFurnishType(e.target.value)}
                            >
                                <FormControlLabel
                                    value="Furnished"
                                    control={<Radio color="primary" />}
                                    label="Furnished"
                                />
                                <FormControlLabel
                                    value="Semi Furnished"
                                    control={<Radio color="primary" />}
                                    label="Semi Furnished"
                                />
                                <FormControlLabel
                                    value="Un Furnished"
                                    control={<Radio color="primary" />}
                                    label="Un Furnished"
                                />
                              
                                {/* <FormControlLabel
                                    value="Ready"
                                    control={<Radio color="primary" />}
                                    label="Ready"
                                /> */}
                            </RadioGroup>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={3} xl={3}>
                        <FormControl fullWidth>
                            <InputLabel>No of Bath</InputLabel>
                            <Select
                                value={bathrooms}
                                onChange={(e) => setBathrooms(e.target.value)}
                            >
                                {Array.from({ length: 7 }, (_, i) => i + 1).map((num, index) => (
                                    <MenuItem key={index} value={num}>
                                        {num} Bathroom{num !== 1 ? 's' : ''}
                                    </MenuItem>
                                ))}
                            </Select>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} lg={4} xl={4}>
                        <FormControl fullWidth>
                            <InputLabel>No of Bedrooms</InputLabel>
                            <Select
                                value={bedrooms}
                                onChange={(e) => setBedrooms(e.target.value)}
                            >
                                {Array.from({ length: 7 }, (_, i) => i + 1).map((num, index) => (
                                    <MenuItem key={index} value={num}>
                                        {num} Bedroom{num !== 1 ? 's' : ''}
                                    </MenuItem>
                                ))}
                                {/* <MenuItem value={'studio'}>Studio</MenuItem> */}
                            </Select>
                        </FormControl>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>

                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <FormControl fullWidth>
                            <InputLabel>Property Type</InputLabel>
                            <Select
                                value={propertyType}
                                onChange={(e) => {
                                    // console.log(e.target)
                                    setPropertyType(e.target.value)
                                }}
                            // defaultValue={PropertyDetails.type}
                            >

                                {
                                    property_types.map((e, index) => {
                                        return (
                                            <MenuItem key={index} value={e.id}>{e.name}</MenuItem>
                                        )
                                    })
                                }
                            </Select>
                            <FormHelperText className='text-danger'>{errors.propertyType}</FormHelperText>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} lg={3} xl={3}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Status</FormLabel>
                            <RadioGroup
                                row
                                value={listingType}
                                onChange={(e) => setListingType(e.target.value)}
                            >
                                <FormControlLabel
                                    value="Rent"
                                    control={<Radio color="primary" />}
                                    label="Rent"
                                />
                                <FormControlLabel
                                    value="Sale"
                                    control={<Radio color="primary" />}
                                    label="Sale"
                                />
                                {/* <FormControlLabel
                                    value="Listed"
                                    control={<Radio color="primary" />}
                                    label="Listed"
                                />
                                <FormControlLabel
                                    value="Pocket"
                                    control={<Radio color="primary" />}
                                    label="Pocket"
                                /> */}
                                {/* <FormControlLabel
                                    value="Ready"
                                    control={<Radio color="primary" />}
                                    label="Ready"
                                /> */}
                            </RadioGroup>
                        </FormControl>
                    </Grid>

                    <Grid item xs={12} md={12} lg={3} xl={3}>
                        <FormControl component="fieldset">
                            <FormLabel component="legend">Is listed ?</FormLabel>
                            <Checkbox onClick={() => setIsListed(!isListed)}    checked={isListed} />
                            </FormControl>
                            </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                
                    <Grid item xs={12} md={12} lg={4} xl={4}>

<FormControl fullWidth>
    <InputLabel>Developer</InputLabel>
    <Select
        value={developer}
        onChange={(e) => setDeveloper(e.target.value)}
    >
        {
            developers.map((e, index) => {
                return (
                    <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
                )
            })
        }
    </Select>
    <FormHelperText className='text-danger'>{errors.developer}</FormHelperText>

</FormControl>
</Grid>

                    <Grid item xs={12} md={12} lg={4} xl={4}>
                        <CurrencyInput
                            style={{
                                width: '100%', padding: '15px', boxSizing: 'border-box', border: '1px solid #ccc', borderRadius: '4px',
                                fontSize: '16px', transition: 'border-color 0.3s'
                            }}
                            id="input-example"
                            name="input-name"
                            label="Price"
                            placeholder="Price"
                            //   defaultValue={0}
                            value={expectedPrice}
                            decimalsLimit={2}
                            onValueChange={(value, name, values) => setExpectedPrice(value)}
                            error={!!errors.expectedPrice}
                            helperText={errors.expectedPrice}
                        />
                        <div className='text-danger'>{errors.expectedPrice}</div>
                     
                    </Grid>
                    <Grid item xs={12} md={12} lg={2} xl={2}>
                    <Button variant="contained" type="submit" style={{  backgroundColor: "black",width:'200px',height:'53px' }} onClick={showModal}>
                    Amenities
                   </Button>
                    </Grid>
                    <Grid item xs={12} md={12} lg={2} xl={2}>
                    <Button variant="contained" type="submit" style={{  backgroundColor: "black",width:'200px',height:'53px' }} onClick={showDocumentModal}>
                    Documents
                   </Button>
                    </Grid>
                </Grid>

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>
                
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                    <FormControl fullWidth>
    <InputLabel>Owner</InputLabel>
    <Select
        value={contact}
        onChange={(e) => setContact(e.target.value)}
    >
        {
            contacts.map((e, index) => {
                return (
                    <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
                )
            })
        }
    </Select>

</FormControl>
                    </Grid>
                    <Grid item xs={12} md={12} lg={6} xl={6}>
                        <input type='text' className='form-control' placeholder='url' onChange={(e) => setUrl(e.target.value)} />
                    </Grid>
                </Grid>


<Grid container spacing={2} style={{ marginTop: '2rem' }}>

                <Grid item xs={12} md={12} lg={12} xl={12}>
                <textarea class="form-control" placeholder="Description" value={description} onChange={(e) =>setDescription(e.target.value)}></textarea>

                    </Grid>
                    </Grid>
            

                <Grid container spacing={2} style={{ marginTop: '2rem' }}>

                <Grid item xs={12} md={12} lg={12} xl={12}>
                        {/* <TextField
                                label="Location"
                                value={location}
                                onChange={(e) => setLocation(e.target.value)}
                                fullWidth
                            /> */}
                        <FormControl fullWidth>
                            <InputLabel>location</InputLabel>
                            {/* <Select
                                className=" wizard-required"
                                options={locations}
                                onChange={(opt) => this.handleLocation(opt)}
                                id="location_selected"
                              /> */}
                            <Select
                           
                                value={location}
                             
                                onChange={ handleLocation}
                                
                                id="location_selected"
                            >
                                {
                                    locations.map((e, index) => {
                                        return (
                                            <MenuItem key={index} value={e.value}>{e.label}</MenuItem>
                                        )
                                    })
                                }
                            </Select> 
                            <FormHelperText className='text-danger'>{errors.location}</FormHelperText>
                        </FormControl>
                    </Grid>
                 
          {/* <Grid item xs={12} md={12} lg={6} xl={6}>
                        <FormControl fullWidth>
                         
                            <Autocomplete
                                multiple
                                id="checkboxes-tags-demo"
                                options={owner}
                                onChange={handleAutocompleteChange}
                                disableCloseOnSelect
                                getOptionLabel={(option) => option.name}
                                renderOption={(props, option, { selected }) => (
                                    <li {...props}>
                                        <Checkbox


                                            style={{ marginRight: 8 }}
                                            checked={selected}
                                        />
                                        <Avatar style={{ marginRight: '10px' }} src={profileImageBaseUrl + "/" + option.profile_image} />

                                        {option.name}


                                    </li>
                                )}
                                style={{ width: 400 }}
                                renderInput={(params) => (
                                    <TextField {...params} label="Select Users" placeholder="" />
                                )}
                            />
                       
                        </FormControl>
                    </Grid> */}
                </Grid>
                <Grid container spacing={2} style={{ marginTop: '2rem',marginBottom:'5px' }}>

<Grid item xs={12} md={12} lg={12} xl={12}>
    
    
           
            <div>
                <div className="map-wrapper">
                                  <div
                                    className="map-container"
                                    id="google-map"
                                  ></div>
                 </div>
                 <Helmet>
                                    <script
                                      type="text/javascript"
                                      charset="UTF-8"
                                      async={true}
                                      defer={true}
                                      src="https://maps.googleapis.com/maps/api/js?key=AIzaSyCq6FzlBsSuT-S2zaRh2Rd-i4205DCu57s&callback=initMap&libraries=places"
                                    />
                                  </Helmet>
                                  </div>

                 </Grid>
                 </Grid>

                <Button onClick={handleSubmit} variant="contained" type="submit" style={{ marginTop: '1rem', backgroundColor: "black", }} disabled={loading} >
                    {
                        loading ?
                            (
                                <>
                                    <BeatLoader color="white" size={5} />
                                    Processing
                                </>
                            ) : (
                                <>
                                    Add Property
                                </>
                            )
                    }

                </Button>

            </div>
        </div>
        // </Container>
    )
}

export default AddPropertyComp;
