import React, { useEffect, useState } from 'react';
import './HomePageComp.css';
import HomePageParticles from './HomeParticle';
import { Tooltip, Typography, Paper, Grid, Divider, Avatar, CircularProgress, MobileStepper, Button, Box, useTheme } from '@mui/material';
import { Bar, BarChart, CartesianGrid, Legend, ResponsiveContainer, XAxis, YAxis } from 'recharts';
import { LineChart, Line, AreaChart, Area, PieChart, Pie, Cell, } from 'recharts';

import { makeStyles } from '@material-ui/core/styles';
import PersonIcon from '@mui/icons-material/Person';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import CakeIcon from '@mui/icons-material/Cake';
import LanguageIcon from '@mui/icons-material/Language';
import FingerprintIcon from '@mui/icons-material/Fingerprint';
import { useSelector } from 'react-redux';

// Local

import { GET_TOTAL_NUMBER_OF_PROPERTIES } from '../Pages/Server/Apis';
import { AmountSaperator } from '../Utilities';
import { httpRequest } from '../Pages/Server/Axios';
import { profileImageBaseUrl } from '../Pages/Server/baseUrls';
import { KeyboardArrowLeft, KeyboardArrowRight } from '@mui/icons-material';
import { Skeleton } from 'antd';
import moment from 'moment';
import BirthdayCard from './BirthdayCard_Comp';

const steps = [
    {
        label: 'Upcoming First Payment Notification ',
        description: `We are writing to inform you about an important upcoming payment related to one of your properties. This notification is to ensure you are aware of the impending transaction and can make any necessary arrangements in advance..`,
        propertyDetail: " Sky Residence 2 ",
        paymentDescription: " 3rd Installment",
        Amount: " 14,58,950",
        DueDate: "1st June 2024"
    },
    {
        label: 'Upcoming Second Payment Notification',
        description: 'We are writing to inform you about an important upcoming payment related to one of your properties. This notification is to ensure you are aware of the impending transaction and can make any necessary arrangements in advance.',
        propertyDetail: " ELA By Omniyat ",
        paymentDescription: " 3rd Installment",
        Amount: " 14,58,950",
        DueDate: "1st June 2024"
    },
    {
        label: 'Upcoming Third Payment Notification',
        description: `We are writing to inform you about an important upcoming payment related to one of your properties. This notification is to ensure you are aware of the impending transaction and can make any necessary arrangements in advance.`,
        propertyDetail: " Mangrove Tower 2 ",
        paymentDescription: " 3rd Installment",
        Amount: " 14,58,950",
        DueDate: "1st June 2024"
    },
];

const HomePageComp_2 = () => {
    const tokenInfo = useSelector((state) => state.login.token);
    const userInfo = useSelector((state) => state.login.user);
    const theme = useTheme();

    const [propertiesList, setPropertiesList] = useState([]);
    const [totalProperty, setTotalProperty] = useState("");
    const [totalAmountOfProperty, setTotalAmountOfProperty] = useState(0);
    const [propertyStatus, setPropertyStatus] = useState({});
    const [loader, setLoader] = useState(false);
    const [error, setError] = useState('');
    const [impUpdates, setImpUpdates] = useState([]);
    const [isBirthDay, setBirthDay] = useState(false);
    const [activeStep, setActiveStep] = useState(0);
    // const maxSteps = steps.length;
    const maxSteps = impUpdates.length;

    const profileImages = `${profileImageBaseUrl}/${userInfo.profile_image}`;

    const SalesVolumeData = [
        { name: 'Villa', Properties: 2 },
        { name: 'Apartment', Properties: 17 },
        { name: 'Duplex', Properties: 0 },
        { name: 'Mansions', Properties: 0 },
        { name: 'Penthouse', Properties: 0 },
        { name: 'Townhouse', Properties: 0 }
    ];

    const data = [
        { name: 'Apartment', value: 17 },
        { name: 'Villa', value: 2 },
        { name: 'Duplex', value: 0 },
        { name: 'Mansions', value: 0 },
        { name: 'Penthouse', value: 0 },
        { name: 'Townhouse', value: 0 }
    ];

    const COLORS = [
        '#222222',
        '#888888',
        '#999999',
        '#aaaaaa',
        '#bbbbbb',
        '#cccccc',
    ]

    useEffect(() => {
        totalNumberOfProperties();
    }, []);

    const totalNumberOfProperties = async () => {
        setLoader(true);
        const API_LIST_OF_TOTAL_PROPERTIES = GET_TOTAL_NUMBER_OF_PROPERTIES(tokenInfo);
        try {
            const response = await httpRequest(API_LIST_OF_TOTAL_PROPERTIES);
            // console.log("**** List of Properties ****** ", response)
            if (response.success) {
                setPropertiesList(response.propertyPerTypes.map((item) => ({ name: item.property_type, value: item.total_properties })));
                setTotalProperty(response.total.total_property);
                setTotalAmountOfProperty(response.total.total_amount_of_property);
                setPropertyStatus(response.propertyStatus);
                setImpUpdates(response.upcomingPayments);
                setBirthDay(response.birthDay !== null ? true : false);
                setLoader(false);
            }
        } catch (error) {
            setLoader(false);
            setError(error)
        }
    }

    const handleNext = () => {
        setActiveStep((prevActiveStep) => prevActiveStep + 1);
    };

    const handleBack = () => {
        setActiveStep((prevActiveStep) => prevActiveStep - 1);
    };

    return (
        <div className="container2">
            <div className="content2">
                {/* <div className='title_style' >
                    <Typography variant='h6' style={{ color: "black", fontSize: '1.5rem', fontWeight: '300', fontFamily: "Montserrat" }} >
                        Welcome, Mr {userInfo.name}
                    </Typography>
                    <Typography variant='body2' style={{ color: "black", fontSize: '0.67rem', fontWeight: '300', fontFamily: "Montserrat" }} >
                        YOUR ASSETS/PORFOLIO/PROPERTIES
                    </Typography>
                </div> */}
                <Grid container spacing={1} marginTop={'2rem'}  >
                    {/* <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display:'flex', alignItems:"center", justifyContent:"center", paddingRight:'2rem' }} minHeight={250}  >
                        {
                            profileImages?
                                <Avatar className='avatar-1' src={profileImages} style={{ width: '10rem', height: '10rem', backgroundColor: 'black' }} />
                                :
                                <Avatar className='avatar-1'  style={{ width: '10rem', height: '10rem', backgroundColor: 'black' }} >
                                    <Typography style={{ color:'white', fontSize:'3rem', fontWeight: '600', fontFamily:'Montserrat'}} >
                                        RCR
                                    </Typography>
                                </Avatar>

                        }

                        <Grid marginLeft={'1rem'}  width={'auto'}  >

                            <Grid item xs={12}>
                                <Typography color={'black'} fontWeight={'300'} variant="body1" marginTop={'0.5rem'} > {userInfo.name? userInfo.name : ""} </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography  color={'black'} fontWeight={'300'} variant="body1" marginTop={'0.5rem'} > {userInfo.email? userInfo.email : ""} </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography  color={'black'} fontWeight={'300'} variant="body1" marginTop={'0.5rem'} > {userInfo.phone? userInfo.phone : ""} </Typography>
                            </Grid>

                            <Grid item xs={12}>
                                <Typography  color={'black'} fontWeight={'300'} variant="body1" marginTop={'0.5rem'} > {userInfo.address? userInfo.address : ""} </Typography>
                            </Grid>

                        </Grid>

                    </Grid> */}

                    <Grid item xs={12} sm={12} md={8} lg={8} xl={8} className='pieChart_Container' style={{ }}   >
                        <div className='title_style' >
                            <Typography variant='h6' style={{ color: "black", fontSize: '1.5rem', fontWeight: '300', fontFamily: "Montserrat" }} >
                                Welcome, Mr {userInfo.name} {isBirthDay ? 'Wish You a very Happy Birthday' : null}
                            </Typography>
                            <Typography variant='body2' style={{ color: "black", fontSize: '0.67rem', fontWeight: '300', fontFamily: "Montserrat" }} >
                                YOUR ASSETS/PORFOLIO/PROPERTIES
                            </Typography>
                        </div>
                       <div style={{ backgroundColor:"#f4f4f4", width:"100%", display: 'flex', flexDirection: 'column', alignItems: "center", justifyContent: "center", marginTop:"1rem"  }} >
                            <div className='pieContainer' >
                                {/* <Typography variant="subtitle1" style={{ textAlign: "center", fontSize: '1.5rem', color: 'black', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                    Properties: {totalProperty ? totalProperty : " -- "}
                                </Typography> */}

                                <ResponsiveContainer width={'100%'} height={200} style={{ marginTop: '1rem' }} >
                                    {
                                        loader ?
                                            <div style={{ height: "10rem", display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'column' }} >
                                                <CircularProgress variant='indeterminate' style={{ color: 'black' }} />
                                                <Typography style={{ textAlign: "center", fontSize: '0.85rem', color: '#666666', fontWeight: '300', fontFamily: 'Montserrat', marginTop: "0.5rem" }}>Loading...</Typography>
                                            </div>
                                            :
                                            (
                                                error ?
                                                    <div style={{ height: "10rem", display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'column' }} >
                                                        <Typography style={{ textAlign: "center", fontSize: '0.85rem', color: '#666666', fontWeight: '300', fontFamily: 'Montserrat', marginTop: "0.5rem" }}> No Data</Typography>
                                                    </div>
                                                    :
                                                    (
                                                        propertiesList.length !== 0 ?
                                                            <PieChart>
                                                                <Pie
                                                                    data={propertiesList}
                                                                    // data={data}
                                                                    innerRadius={55}
                                                                    outerRadius={70}
                                                                    cx="50%"
                                                                    cy="50%"
                                                                    fill="#8884d8"
                                                                    paddingAngle={5}
                                                                    dataKey="value"
                                                                    label={({ name, value }) => value == 0 ? null : `${name}: ${value}`}
                                                                    labelLine={false}
                                                                >
                                                                    {propertiesList.map((entry, index) => (
                                                                        // {data.map((entry, index) => (
                                                                        <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                                                    ))}
                                                                </Pie>
                                                            </PieChart>
                                                            :
                                                            <Typography style={{ textAlign: "center", fontSize: '0.85rem', color: '#666666', fontWeight: '300', fontFamily: 'Montserrat', marginTop: "3rem" }}> No Data</Typography>
                                                    )
                                            )
                                    }

                                </ResponsiveContainer>

                                {/* <Typography variant="body1" style={{textAlign:"center", fontSize: '1.5rem',color:'black', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                    <Typography style={{textAlign:"center", fontSize: '0.85rem',color:'#666666', fontWeight: '300', fontFamily: 'Montserrat' }}>Total Portfolio Price:</Typography> AED { totalAmountOfProperty ? AmountSaperator( totalAmountOfProperty) : 0 }
                                </Typography> */}

                                <Typography style={{ textAlign: "center", fontSize: '0.85rem', color: '#666666', fontWeight: '300', fontFamily: 'Montserrat' }}>
                                    Total Portfolio Price:
                                </Typography>

                                <Typography variant="body1" style={{ textAlign: "center", fontSize: '1.5rem', color: 'black', fontWeight: '500', fontFamily: 'Montserrat' }}>
                                    AED {totalAmountOfProperty ? AmountSaperator(totalAmountOfProperty) : 0}
                                </Typography>


                                <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'space-around', width: '50%', marginTop: '1rem' }}>
                                    {/* <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start' }} >
                                        <Typography variant="body1" style={{textAlign:"center", fontSize: '0.85rem',color:'black', fontWeight: '300', fontFamily: 'Montserrat' }} >
                                            Rent:
                                        </Typography>
                                        <Typography variant="body1" style={{textAlign:"center", fontSize: '1rem',color:'black', fontWeight: '400', fontFamily: 'Montserrat', marginLeft:'0.25rem' }} >
                                            13
                                        </Typography>
                                    </div>
                                    <div style={{ display:'flex', flexDirection:"row", alignItems:"center", justifyContent:'flex-start' }} >
                                        <Typography variant="body1" style={{textAlign:"center", fontSize: '0.85rem',color:'black', fontWeight: '300', fontFamily: 'Montserrat' }} >
                                            Sold:
                                        </Typography>
                                        <Typography variant="body1" style={{textAlign:"center", fontSize: '1rem',color:'black', fontWeight: '400', fontFamily: 'Montserrat', marginLeft:'0.25rem' }} >
                                            3
                                        </Typography>
                                    </div> */}

                                    <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'flex-start' }} >
                                        <Typography variant="body1" style={{ textAlign: "center", fontSize: '0.85rem', color: 'black', fontWeight: '300', fontFamily: 'Montserrat' }} >
                                            Off-Plan:
                                        </Typography>
                                        <Typography variant="body1" style={{ textAlign: "center", fontSize: '1rem', color: 'black', fontWeight: '400', fontFamily: 'Montserrat', marginLeft: '0.25rem' }} >
                                            {propertyStatus.Off_plan == null || propertyStatus.Off_plan == "" ? 0 : propertyStatus.Off_plan}
                                            {/* 4 */}
                                        </Typography>
                                    </div>
                                    <div style={{ display: 'flex', flexDirection: "row", alignItems: "center", justifyContent: 'flex-start' }} >
                                        <Typography variant="body1" style={{ textAlign: "center", fontSize: '0.85rem', color: 'black', fontWeight: '300', fontFamily: 'Montserrat' }} >
                                            Ready:
                                        </Typography>
                                        <Typography variant="body1" style={{ textAlign: "center", fontSize: '1rem', color: 'black', fontWeight: '400', fontFamily: 'Montserrat', marginLeft: '0.25rem' }} >
                                            {propertyStatus.Ready == null || propertyStatus.Ready == "" ? 0 : propertyStatus.Ready}
                                            {/* 1 */}
                                        </Typography>
                                    </div>

                                </div>
                            </div>
                       </div>
                    </Grid>

                    <Grid item  xs={12} sm={12} md={4} lg={4} xl={4} style={{ display: 'flex', flexDirection: 'column', alignItems: "flex-start", justifyContent: "flex-start", paddingLeft:"0.5rem" }} >
                        <Typography variant="body2" style={{ textAlign: "left", fontSize: '0.67rem', color: 'black', fontWeight: '300', fontFamily: 'Montserrat', marginTop:"2.5rem" }}>
                            IMPORTANT UPDATES
                        </Typography>

                        {/* <Typography variant='body2' style={{ color: "black", fontSize: '0.67rem', fontWeight: '300', fontFamily: "Montserrat" }} >
                            IMPORTANT UPDATES
                        </Typography> */}

                        {
                            loader ?
                                <Skeleton active  paragraph={{ rows: 4, width: ['100%', '50%', '50%', '50%'] }} title={{ width: '100%' }} style={{ marginTop:"13rem" }} />
                                :
                                (
                                    error ?
                                        <div style={{ height: "10rem", display: 'flex', alignItems: "center", justifyContent: "center", flexDirection: 'column' }} >
                                            <Typography style={{ textAlign: "center", fontSize: '0.85rem', color: '#666666', fontWeight: '300', fontFamily: 'Montserrat', marginTop: "0.5rem" }}> No Data</Typography>
                                        </div>
                                        :
                                        (
                                            <Box sx={{ maxWidth: 400, flexGrow: 1 }}>
                                                <Paper
                                                    square
                                                    elevation={0}
                                                    sx={{
                                                        display: 'flex',
                                                        alignItems: 'center',
                                                        height: 50,
                                                        // pl: 2,
                                                        bgcolor: 'background.default',
                                                    }}
                                                >
                                                    <Typography
                                                        variant="subtitle1"
                                                        style={{
                                                            textAlign: 'left',
                                                            fontSize: '1rem',
                                                            color: 'black',
                                                            fontWeight: '400',
                                                            fontFamily: 'Montserrat',
                                                        }}
                                                    >
                                                        Notification For Upcoming Payments
                                                    </Typography>
                                                </Paper>
                                                <Box sx={{ height: '15rem', maxWidth: 400, width: '100%', p: 2, display:"flex", alignItems:"flex-end", justifyContent:"flex-start" }}>
                                                    {impUpdates && impUpdates[activeStep] && (
                                                        <div>
                                                            <Typography
                                                                variant="subtitle1"
                                                                style={{
                                                                    fontSize: '0.75rem',
                                                                    color: 'black',
                                                                    fontWeight: '400',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                Property: {impUpdates[activeStep].project || 'N/A'}
                                                            </Typography>

                                                            <Typography
                                                                variant="subtitle1"
                                                                style={{
                                                                    fontSize: '0.75rem',
                                                                    color: 'black',
                                                                    fontWeight: '400',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                Developer:  {impUpdates[activeStep].developer || 'N/A'}
                                                            </Typography>

                                                            <Typography
                                                                variant="subtitle1"
                                                                style={{
                                                                    fontSize: '0.75rem',
                                                                    color: 'black',
                                                                    fontWeight: '400',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                Amount:  {  AmountSaperator(impUpdates[activeStep].amount) || 'N/A'}
                                                            </Typography>

                                                            <Typography
                                                                variant="subtitle1"
                                                                style={{
                                                                    fontSize: '0.75rem',
                                                                    color: 'black',
                                                                    fontWeight: '400',
                                                                    fontFamily: 'Montserrat',
                                                                }}
                                                            >
                                                                Due Date:  {moment(impUpdates[activeStep].date).format('Do MMM YYYY') || 'N/A'}
                                                            </Typography>
                                                        </div>
                                                    )}
                                                </Box>
                                                <MobileStepper
                                                    variant="text"
                                                    steps={maxSteps}
                                                    position="static"
                                                    activeStep={activeStep}
                                                    nextButton={
                                                        <Button
                                                            size="small"
                                                            onClick={handleNext}
                                                            disabled={activeStep === maxSteps - 1}
                                                            color="inherit"
                                                        >
                                                            Next
                                                            {theme.direction === 'rtl' ? (
                                                                <KeyboardArrowLeft />
                                                            ) : (
                                                                <KeyboardArrowRight />
                                                            )}
                                                        </Button>
                                                    }
                                                    backButton={
                                                        <Button
                                                            size="small"
                                                            onClick={handleBack}
                                                            disabled={activeStep === 0}
                                                            color="inherit"
                                                        >
                                                            {theme.direction === 'rtl' ? (
                                                                <KeyboardArrowRight />
                                                            ) : (
                                                                <KeyboardArrowLeft />
                                                            )}
                                                            Back
                                                        </Button>
                                                    }
                                                />
                                            </Box>

                                            // <BirthdayCard {...userInfo} />

                                        )
                                )
                        }

                    </Grid>

                    {/* <Grid item xs={12} sm={12} md={4} lg={4} xl={4} style={{ display:'flex', flexDirection:'column', alignItems:"center", justifyContent:"center" }} >

                    </Grid> */}
                </Grid>
                
                {/* <Grid>
                    <Grid item xs={12} sm={12} md={6} lg={6} xl={6} style={{ display:'flex', alignItems:"center", justifyContent:"center", paddingRight:'2rem' }} minHeight={250}  >
                        <BirthdayCard {...userInfo} />
                    </Grid>
                </Grid> */}
            </div>
        </div>


    );
}

export default HomePageComp_2;
